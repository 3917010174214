export const SystemErrorIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 140 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M66.1029 7.75C67.8349 4.75 72.1651 4.75 73.8971 7.75L125.859 97.75C127.591 100.75 125.426 104.5 121.962 104.5H18.0385C14.5744 104.5 12.4093 100.75 14.1414 97.75L66.1029 7.75Z"
        fill="#FFDA0A"
        stroke="#575757"
      />
      <path
        d="M69.4609 75.4453L64.6562 45.4453C64.5781 44.9245 64.513 44.4427 64.4609 44C64.4349 43.5573 64.4219 43.1667 64.4219 42.8281C64.4219 40.7969 64.9688 39.2083 66.0625 38.0625C67.1562 36.9167 68.6927 36.3438 70.6719 36.3438C72.651 36.3438 74.2005 36.9167 75.3203 38.0625C76.4401 39.2083 77 40.7969 77 42.8281C77 43.1667 76.974 43.5573 76.9219 44C76.8958 44.4427 76.8438 44.9245 76.7656 45.4453L71.8828 75.4453H69.4609ZM70.6719 90.4062C68.9792 90.4062 67.5339 89.8203 66.3359 88.6484C65.1641 87.4766 64.5781 86.0443 64.5781 84.3516C64.5781 82.6328 65.1641 81.1875 66.3359 80.0156C67.5339 78.8438 68.9792 78.2578 70.6719 78.2578C72.3385 78.2578 73.7708 78.8568 74.9688 80.0547C76.1667 81.2266 76.7656 82.6589 76.7656 84.3516C76.7656 86.0182 76.1667 87.4505 74.9688 88.6484C73.7708 89.8203 72.3385 90.4062 70.6719 90.4062Z"
        fill="black"
      />
    </svg>
  );
};
