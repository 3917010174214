import React, { createContext, useContext, useState, ReactNode } from "react";
import { useRouter } from "next/navigation";
import SystemModal from "@/components/common/system_modal";
import { useReactiveVar } from "@apollo/client";
import { homeURLVar, userDataVar } from "../utils/apollo";
import { useSocketManager } from "./SocketManagerContext";

export enum AlertType {
  LOGOUT = 1,
  LOBBY = 2,
  RESERVE1 = 3,
  RESERVE2 = 4,
  IDLE = 5,
}

interface ISystemAlertContext {
  isSystemOpen: boolean;
  showModal: (message: string, alrtcode: number, alrttype?: AlertType) => void;
  hideModal: () => void;
}

const SystemAlertContext = createContext<ISystemAlertContext | undefined>(
  undefined,
);

export function SystemAlertProvider({ children }: { children: ReactNode }) {
  const [isSystemOpen, setIsSystemOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [alrtCode, setAlrtCode] = useState(0);
  const [alrtType, setAlrtType] = useState<AlertType | undefined>(undefined);

  const router = useRouter();
  //const { dispatchEvent } = useEventDispatcher();
  const userData = useReactiveVar(userDataVar);
  const homeURL = useReactiveVar(homeURLVar);

  const showModal = (
    message: string,
    alrtcode: number,
    alrttype?: AlertType,
  ) => {
    setMessage(message);
    setAlrtCode(alrtcode);
    setAlrtType(alrttype);
    setIsSystemOpen(true);
  };

  const hideModal = () => {
    setIsSystemOpen(false);
  };

  const onCloseModal = () => {
    //dispatchEvent("cardclick");
    if (alrtType) {
      if (alrtType === 1) {
        if (userData && userData.user_type) {
          if (userData.user_type === "A") {
            sessionStorage.clear();
            window.location.assign(homeURL);
            router.push(homeURL);
            router.refresh();
          } else {
            sessionStorage.clear();
            window.location.assign("/login");
            router.push("/login");
            router.refresh();
          }
        }
      } else if (alrtType === 5) {
        router.refresh();
      } else {
        router.push("/lobby");
        router.refresh();
      }
    }

    hideModal();
  };

  return (
    <SystemAlertContext.Provider value={{ isSystemOpen, showModal, hideModal }}>
      {children}
      {isSystemOpen && (
        <SystemModal
          code={alrtCode}
          message={message}
          alrtType={alrtType}
          onCloseModal={onCloseModal}
        />
      )}
    </SystemAlertContext.Provider>
  );
}

export function useSystemAlert() {
  const context = useContext(SystemAlertContext);
  if (!context) {
    throw new Error("useSystemAlert must be used within a SystemAlertProvider");
  }
  return context;
}
