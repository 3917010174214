import React from "react";
import { useTranslation } from "react-i18next";
import { SystemErrorIcon } from "../multibet/svg-Images/systemIcons";
import { ExitIcon } from "../multibet/svg-Images/flagIcons";

const TIMER_COUNTDOWN = 10;
const PAUSE_DURATION = 3;
const IS_PAUSE_ENABLED = false; // Feature flag to enable/disable pause functionality

const useModalTimer = (onComplete: () => void, isTimerEnabled: boolean) => {
  const [timeLeft, setTimeLeft] = React.useState(TIMER_COUNTDOWN);
  const [isTimerActive, setIsTimerActive] = React.useState(isTimerEnabled);
  const lastMouseMoveRef = React.useRef<number>(Date.now());
  const timerRef = React.useRef<NodeJS.Timeout | null>(null);

  const clearTimer = React.useCallback(() => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const startCountdown = React.useCallback(() => {
    if (!isTimerEnabled) return; // Exit if the timer is not enabled

    clearTimer();
    setIsTimerActive(true);

    timerRef.current = setInterval(() => {
      const currentTime = Date.now();
      const timeSinceLastMove = (currentTime - lastMouseMoveRef.current) / 1000;

      if (IS_PAUSE_ENABLED && timeSinceLastMove < PAUSE_DURATION) {
        return;
      }

      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearTimer();
          onComplete();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  }, [clearTimer, onComplete, isTimerEnabled]);

  const handleMouseMove = React.useCallback(() => {
    lastMouseMoveRef.current = Date.now();
    setTimeLeft(TIMER_COUNTDOWN);
    setIsTimerActive(false);

    if (!timerRef.current) {
      startCountdown();
    }
  }, [startCountdown]);

  React.useEffect(() => {
    if (isTimerEnabled) {
      startCountdown();
    }
    if (IS_PAUSE_ENABLED) {
      window.addEventListener("mousemove", handleMouseMove);
      return () => {
        clearTimer();
        window.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, [startCountdown, handleMouseMove, clearTimer, isTimerEnabled]);

  const isPaused = React.useMemo(() => {
    if (!IS_PAUSE_ENABLED) return false;
    const timeSinceLastMove = (Date.now() - lastMouseMoveRef.current) / 1000;
    return timeSinceLastMove < PAUSE_DURATION;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft]);

  return {
    timeLeft,
    isPaused,
    isTimerActive,
  };
};

interface SystemModalProps {
  code: number;
  message: string;
  alrtType?: number;
  onCloseModal: () => void;
}

const SystemModal: React.FC<SystemModalProps> = ({
  code,
  message,
  alrtType,
  onCloseModal,
}) => {
  const { t } = useTranslation();
  const isTimerEnabled = alrtType !== 5 || !alrtType; // No timer for alert type 5 or if alert type is undefined
  const { timeLeft, isPaused } = useModalTimer(onCloseModal, isTimerEnabled);

  return (
    <div className="fixed inset-0 z-[999] grid h-screen w-screen place-items-center bg-black/40 pl-4 pr-6 text-white">
      <div className="relative flex h-[23%] w-full max-w-[500px] items-center justify-center rounded-md border border-[#686868] bg-[#171818] text-center [container-type:inline-size]">
        <div className="absolute left-[7%] top-1/2 w-[15%] -translate-y-1/2">
          <SystemErrorIcon />
        </div>
        <div
          onClick={onCloseModal}
          className="absolute right-[3%] top-[5%] w-[3%]"
        >
          <ExitIcon />
        </div>
        <div className="absolute left-[20%] top-[40%] h-auto w-[70%] -translate-y-1/2 text-[3.5cqw]">
          <p>Error {code}:</p>
          <p className="text-clip font-bold">{t(message)}</p>

          {isTimerEnabled && timeLeft > 0 && (
            <p className="text-sm text-gray-300">
              {!isPaused && `${t("Closing in")} ${timeLeft} ${t("seconds")}`}
            </p>
          )}
        </div>
        <button
          className="absolute bottom-0 left-0 w-full rounded-b-md bg-[#282929] p-[2%]"
          onClick={onCloseModal}
        >
          {t("Confirm")}
        </button>
      </div>
    </div>
  );
};

export default SystemModal;
