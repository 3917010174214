interface ErrorRange {
  range: number[];
  message: string;
}

export const ErrorCodeMsg: ErrorRange[] = [
  // System Errors
  {
    range: [50000, 50001, 50003, 50005, 50006],
    message: "System encountered an error. Please try again",
  },
  { range: [40102], message: "Connection has ended." },
  {
    range: [10020],
    message:
      "We've detected a recent login to your account. Please log in again",
  },

  // Game Errors
  { range: [10020], message: "No Game Activity Has Been Detected" },
  { range: [10035], message: "Connection Ends Due to Network Error" },
  // Betting Errors
  {
    range: [40042],
    message: "Betting encountered an error. Please try again.",
  },
];

export const getErrorMessage = (code: number, message: string): string => {
  const error = ErrorCodeMsg.find(({ range }) => range.includes(code));
  return error ? error.message : message;
};

const getsdErrorMessage = (
  code: number,
  message: string | string[],
): string | string[] => {
  const error = ErrorCodeMsg.find(
    ({ range }) => code >= range[0] && code <= range[1],
  );
  return error ? error.message : message;
};
