import { useReactiveVar } from "@apollo/client";
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { selectedTablesVar, userBalance } from "../utils/apollo";

interface Deduction {
  key: string;
  value: number;
}

interface BalanceContextType {
  actualBalance: number;
  displayBalance: number;
  deductions: Deduction[];
  addOrUpdateDeduction: (key: string, value: number) => void;
  removeDeduction: (key: string) => void;
  resetDisplayBalance: () => void;
}

const BalanceContext = createContext<BalanceContextType | null>(null);

export const BalanceDisplayProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const raw_balance = useReactiveVar(userBalance);
  const activeTables = useReactiveVar(selectedTablesVar);

  const actualBalance = useMemo(() => {
    const balance = Number(raw_balance);
    return isNaN(balance) ? 0 : balance;
  }, [raw_balance]);

  const [deductions, setDeductions] = useState<Deduction[]>([]);
  const [displayBalance, setDisplayBalance] = useState<number>(actualBalance);

  // Function to calculate the display balance dynamically
  const calculateDisplayBalance = useCallback(() => {
    const totalDeductions = deductions.reduce(
      (acc, curr) => acc + curr.value,
      0,
    );
    return Math.max(0, actualBalance - totalDeductions);
  }, [actualBalance, deductions]);

  // Automatically update the display balance whenever actualBalance or deductions change
  useEffect(() => {
    setDisplayBalance(calculateDisplayBalance());
  }, [actualBalance, calculateDisplayBalance, deductions]);

  // Automatically remove deduction once the tableId is not in activeTables
  useEffect(() => {
    setDeductions((prevDeductions) =>
      prevDeductions.filter((deduction) =>
        activeTables.some(
          (table) => table.tableId === deduction.key && table.isLobby === false,
        ),
      ),
    );
  }, [activeTables]);

  // Function to add or update a deduction by key
  const addOrUpdateDeduction = (key: string, value: number) => {
    setDeductions((prevDeductions) => {
      const existingDeduction = prevDeductions.find(
        (deduction) => deduction.key === key,
      );
      if (existingDeduction) {
        return prevDeductions.map((deduction) =>
          deduction.key === key ? { ...deduction, value } : deduction,
        );
      } else {
        return [...prevDeductions, { key, value }];
      }
    });
  };

  // Function to remove a deduction by key
  const removeDeduction = (key: string) => {
    setDeductions((prevDeductions) =>
      prevDeductions.filter((deduction) => deduction.key !== key),
    );
  };

  // Function to reset display balance to match actual balance
  const resetDisplayBalance = () => {
    setDeductions([]);
    setDisplayBalance(actualBalance);
  };

  return (
    <BalanceContext.Provider
      value={{
        actualBalance,
        displayBalance,
        deductions,
        addOrUpdateDeduction,
        removeDeduction,
        resetDisplayBalance,
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
};

export const useBalance = () => {
  const context = useContext(BalanceContext);
  if (!context) {
    throw new Error("useBalance must be used within a BalanceDisplayProvider");
  }
  return context;
};
