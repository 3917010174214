import { useReactiveVar } from "@apollo/client";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Manager } from "socket.io-client";
import {
  accessToken as accessTokenVar,
  currencyRate,
  userBalance,
  userDataVar,
} from "../utils/apollo";
import { SESSIONSTORAGE_BALANCE } from "../utils/constants";
import { GenerateKeyPayload } from "@/components/loginSubmit/login_submit";
import { useSystemAlert } from "./SystemAlertProvider";
import { ILogoutData } from "../api/listeners/table.listener";
import { getErrorMessage } from "../utils/errorMsgUtils";

const manager = new Manager(process.env.NEXT_PUBLIC_API_URL!, {
  transports: ["websocket"],
  autoConnect: false,
});

type SocketManagerContext = {
  manager: Manager;
  connected: boolean;
};

const SocketManagerContext = createContext<SocketManagerContext | undefined>(
  undefined,
);

export function useSocketManager() {
  const context = useContext(SocketManagerContext);
  if (context === undefined) {
    throw new Error(
      "useSocketManager must be used within a SocketManagerProvider.",
    );
  }
  return context;
}

export function SocketManagerProvider({ children }: { children: ReactNode }) {
  const socket = manager.socket("/");
  const userData = useReactiveVar(userDataVar);
  const accessToken = useReactiveVar(accessTokenVar);
  const { showModal } = useSystemAlert();
  const [connected, setConnected] = useState(false);
  // const [connected, setConnected] = useState(socket.connected);
  const [sysType, setSysType] = useState(0);

  const onConnectHandler = useCallback(() => {
    console.log("Connected to main namespace");
    if (userData && accessToken) {
      socket.emit(
        "event:user:connect",
        {
          agent_id: userData.agent_id,
          user_id: userData.user_id,
          user_type: userData.user_type,
          token: accessToken,
        },
        (data: GenerateKeyPayload) => {
          if (data.code === 0) {
            console.log("On Generate Key Login");
            userBalance(data.balance.toString());
            sessionStorage.setItem(
              SESSIONSTORAGE_BALANCE,
              data.balance.toString(),
            );
            currencyRate(Number(data.rate));
            sessionStorage.setItem("currency_rate", data.rate);
            setConnected(true);
          } else {
            alert("Internal server error. Please try again later.");
            setConnected(false);
          }
        },
      );
      console.log("User connect emitted");
    }
  }, [userData, accessToken, socket]);

  const onDisconnectHandler = useCallback(() => {
    console.log("Disconnected to main namespace");
    setConnected(false);
  }, []);

  const sessionStatusHandler = useCallback(
    (data: ILogoutData) => {
      setSysType(data.type);
      const message = getErrorMessage(data.code, data.message);
      showModal(message, data.code, data.type);
    },
    [showModal],
  );

  useEffect(() => {
    // Prevent Reconnecting once rerouting is to login
    if (sysType !== 1) {
      socket.on("connect", onConnectHandler);
      socket.on("disconnect", onDisconnectHandler);
      socket.on("event:session:status", sessionStatusHandler);
    }
    return () => {
      socket.off("connect", onConnectHandler);
      socket.off("disconnect", onDisconnectHandler);
      socket.off("event:session:status", sessionStatusHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onConnectHandler, onDisconnectHandler, sessionStatusHandler, socket]);

  useEffect(() => {
    const handleOffline = () => {
      socket.disconnect();
      //console.log("Disconnected due to offline status");
    };

    const handleOnline = () => {
      socket.connect();
      //console.log("Reconnected due to online status");
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, [socket]);

  return (
    <SocketManagerContext.Provider value={{ manager, connected }}>
      {children}
    </SocketManagerContext.Provider>
  );
}
