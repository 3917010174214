export const EnglishIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_926371_84060)">
        <path d="M0 0H32V21.3336H0V0Z" fill="white" />
        <path
          d="M18 0H14V8.66663H0V12.6666H14V21.3332H18V12.6666H32V8.66663H18V0Z"
          fill="#D80027"
        />
        <path
          d="M24.6116 14.377L32 18.4817V14.377H24.6116ZM19.4783 14.377L32 21.3335V19.3663L23.0192 14.377H19.4783ZM28.6646 21.3335L19.4783 16.2295V21.3335H28.6646Z"
          fill="#0052B4"
        />
        <path
          d="M19.4783 14.377L32 21.3335V19.3663L23.0192 14.377H19.4783Z"
          fill="white"
        />
        <path
          d="M19.4783 14.377L32 21.3335V19.3663L23.0192 14.377H19.4783Z"
          fill="#D80027"
        />
        <path
          d="M5.64631 14.376L0 17.5129V14.376H5.64631ZM12.5218 15.2606V21.3325H1.59319L12.5218 15.2606Z"
          fill="#0052B4"
        />
        <path
          d="M8.98081 14.377L0 19.3663V21.3335L12.5218 14.377H8.98081Z"
          fill="#D80027"
        />
        <path
          d="M7.38844 6.9565L0 2.85175V6.9565H7.38844ZM12.5218 6.9565L0 0V1.96712L8.98081 6.9565H12.5218ZM3.33537 0L12.5218 5.104V0H3.33537Z"
          fill="#0052B4"
        />
        <path
          d="M12.5218 6.9565L0 0V1.96712L8.98081 6.9565H12.5218Z"
          fill="white"
        />
        <path
          d="M12.5218 6.9565L0 0V1.96712L8.98081 6.9565H12.5218Z"
          fill="#D80027"
        />
        <path
          d="M26.3537 6.95656L32 3.81969V6.95656H26.3537ZM19.4783 6.07194V0H30.4068L19.4783 6.07194Z"
          fill="#0052B4"
        />
        <path
          d="M23.0192 6.9565L32 1.96713V0L19.4783 6.9565H23.0192Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_926371_84060">
          <rect width={32} height={21.3333} rx={4} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const KoreanIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_926371_84063)">
        <path d="M0 0H32V21.3333H0V0Z" fill="white" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.88556 6.46191L7.84396 2.02431L8.62248 2.54333L5.66409 6.98093L4.88556 6.46191ZM5.99496 7.20151L8.95336 2.76391L9.73188 3.28292L6.77349 7.72053L5.99496 7.20151ZM7.10436 7.94111L10.0628 3.50351L10.8413 4.02252L7.88289 8.46013L7.10436 7.94111ZM21.1568 17.3094L24.1152 12.8718L24.8937 13.3908L21.9353 17.8284L21.1568 17.3094ZM22.2662 18.049L25.2246 13.6114L26.0031 14.1304L23.0447 18.568L22.2662 18.049ZM27.1125 14.87L24.1541 19.3076L23.3756 18.7886L26.334 14.351L27.1125 14.87Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.7674 17.647L22.0694 15.1816L22.5019 14.5329L26.1999 16.9982L25.7674 17.647Z"
          fill="white"
        />
        <path
          d="M20.4366 13.6247C22.0705 11.1739 21.4082 7.86257 18.9574 6.22869C16.5066 4.59482 13.1953 5.25708 11.5614 7.7079C9.92755 10.1587 10.5898 13.47 13.0406 15.1039C15.4915 16.7378 18.8028 16.0755 20.4366 13.6247Z"
          fill="#CA163A"
        />
        <path
          d="M11.5614 7.7079C10.7449 8.93268 11.0762 10.5894 12.301 11.4059C13.5258 12.2224 15.1825 11.8911 15.999 10.6663C16.8155 9.44152 18.4722 9.11018 19.697 9.92669C20.9218 10.7432 21.2532 12.3999 20.4366 13.6247C18.8036 16.0743 15.4902 16.7369 13.0406 15.1039C10.5911 13.4709 9.92839 10.1575 11.5614 7.7079Z"
          fill="#0E4896"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M24.1541 2.02441L27.1125 6.46203L26.334 6.98105L23.3756 2.54343L24.1541 2.02441ZM23.0447 2.76404L26.0031 7.20162L25.2246 7.72064L22.2662 3.28306L23.0447 2.76404ZM21.9353 3.50363L24.8937 7.94121L24.1151 8.46023L21.1567 4.02265L21.9353 3.50363ZM7.88282 12.8719L10.8412 17.3095L10.0627 17.8285L7.1043 13.3909L7.88282 12.8719ZM6.77342 13.6115L9.73182 18.0491L8.95329 18.5681L5.99489 14.1305L6.77342 13.6115ZM5.66409 14.3511L8.62249 18.7887L7.84396 19.3077L4.88556 14.8701L5.66409 14.3511Z"
          fill="black"
        />
        <path
          d="M7.31024 16.4585L8.41965 15.7189L7.31024 16.4585ZM22.2856 6.47488L23.5814 5.61102L22.2856 6.47488ZM24.6908 4.87143L25.8002 4.13184L24.6908 4.87143Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.0165 4.45639L24.9071 5.19598L24.4746 4.54721L25.584 3.80762L26.0165 4.45639ZM23.7977 5.93557L22.5019 6.79943L22.0694 6.15066L23.3652 5.2868L23.7977 5.93557ZM8.63589 16.0435L7.52649 16.783L7.09399 16.1343L8.2034 15.3947L8.63589 16.0435Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_926371_84063">
          <rect width={32} height={21.3333} rx={4} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ChineseIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_926371_84066)">
        <path d="M0 0H32V21.3333H0V0Z" fill="#D80027" />
        <path
          d="M14.1473 14.9204L13.5422 13.9473L12.4257 14.2218L13.168 13.3423L12.5629 12.3692L13.6296 12.7996L14.3656 11.92L14.2845 13.0678L15.3512 13.4982L14.2346 13.7727L14.1473 14.9204ZM18.127 5.11459L17.497 6.07521L18.2206 6.96722L17.1165 6.66781L16.4865 7.63467L16.4241 6.48691L15.32 6.18749L16.3929 5.7758L16.3305 4.62804L17.0541 5.52005L18.127 5.11459ZM14.7337 1.58398L14.6089 2.7255L15.6569 3.19958L14.5341 3.43662L14.4093 4.57814L13.8354 3.58009L12.7189 3.81712L13.4861 2.96254L12.9122 1.97073L13.9602 2.43857L14.7337 1.58398ZM18.2643 10.0924L17.3348 10.7723L17.6966 11.8639L16.7672 11.1902L15.8378 11.8764L16.1871 10.7785L15.2576 10.1111L16.4054 10.1048L16.7547 9.00699L17.1165 10.0986L18.2643 10.0924ZM7.17343 2.88769L8.25257 6.22492H11.7582L8.92626 8.27716L10.0054 11.6144L7.17343 9.55591L4.33522 11.6144L5.4206 8.27716L2.5824 6.22492H6.09429L7.17343 2.88769Z"
          fill="#FFDA44"
        />
      </g>
      <defs>
        <clipPath id="clip0_926371_84066">
          <rect width={32} height={21.3333} rx={4} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const JapaneseIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 32 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_926371_84069)">
        <path d="M0 0H32V21.3336H0V0Z" fill="white" />
        <path
          d="M16 16.666C19.3137 16.666 22 13.9797 22 10.666C22 7.35231 19.3137 4.66602 16 4.66602C12.6863 4.66602 10 7.35231 10 10.666C10 13.9797 12.6863 16.666 16 16.666Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_926371_84069">
          <rect width={32} height={21.3333} rx={4} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LightThemeFlag = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 157 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 0.474609H156.638V46.0781H0V0.474609Z" fill="white" />
      <path
        d="M155.051 2.40039H1.58789V44.5994H155.051V2.40039Z"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M1.58789 37.5664H155.051"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M1.58789 30.5322H155.051"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M1.58789 23.5H155.051"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M1.58789 16.4668H155.051"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M1.58789 9.43359H155.051"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M148.23 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M141.564 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M134.898 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M128.234 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M121.568 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M114.902 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M108.236 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M101.572 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M94.9043 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M88.2402 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M81.5762 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M74.9102 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M68.2441 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M61.5781 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M54.9121 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M48.2461 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M41.582 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M34.916 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M28.25 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M21.584 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M14.9199 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <path
        d="M8.25195 2.40039V44.5994"
        stroke="#BCBCBC"
        strokeWidth={0.153143}
        strokeMiterlimit={10}
      />
      <g clipPath="url(#clip0_928874_135298)">
        <path
          d="M38.1353 10.3525C36.5763 10.3525 35.3086 11.6322 35.3086 13.2059C35.3086 14.7796 36.5763 16.0593 38.1353 16.0593C39.6944 16.0593 40.9621 14.7796 40.9621 13.2059C40.9621 11.6322 39.6944 10.3525 38.1353 10.3525ZM38.1353 15.6225C36.8155 15.6225 35.7413 14.5382 35.7413 13.2059C35.7413 11.8736 36.8155 10.7893 38.1353 10.7893C39.4552 10.7893 40.5294 11.8736 40.5294 13.2059C40.5294 14.5382 39.4552 15.6225 38.1353 15.6225Z"
          fill="#F21D26"
        />
      </g>
      <g clipPath="url(#clip1_928874_135298)">
        <path
          d="M38.1373 17.5703C36.5782 17.5703 35.3105 18.8499 35.3105 20.4237C35.3105 21.9974 36.5782 23.277 38.1373 23.277C39.6964 23.277 40.964 21.9974 40.964 20.4237C40.964 18.8499 39.6964 17.5703 38.1373 17.5703ZM38.1373 22.8403C36.8174 22.8403 35.7433 21.756 35.7433 20.4237C35.7433 19.0914 36.8174 18.0071 38.1373 18.0071C39.4572 18.0071 40.5313 19.0914 40.5313 20.4237C40.5313 21.756 39.4572 22.8403 38.1373 22.8403Z"
          fill="#F21D26"
        />
      </g>
      <g clipPath="url(#clip2_928874_135298)">
        <path
          d="M44.9439 3.39062C43.3849 3.39062 42.1172 4.67025 42.1172 6.24399C42.1172 7.81773 43.3849 9.09736 44.9439 9.09736C46.503 9.09736 47.7707 7.81773 47.7707 6.24399C47.7707 4.67025 46.503 3.39062 44.9439 3.39062ZM44.9439 8.66058C43.6241 8.66058 42.5499 7.5763 42.5499 6.24399C42.5499 4.91169 43.6241 3.82741 44.9439 3.82741C46.2638 3.82741 47.338 4.91169 47.338 6.24399C47.338 7.5763 46.2638 8.66058 44.9439 8.66058Z"
          fill="#F21D26"
        />
      </g>
      <g clipPath="url(#clip3_928874_135298)">
        <path
          d="M58.2857 3.3916C56.7267 3.3916 55.459 4.67123 55.459 6.24497C55.459 7.81871 56.7267 9.09834 58.2857 9.09834C59.8448 9.09834 61.1125 7.81871 61.1125 6.24497C61.1125 4.67123 59.8448 3.3916 58.2857 3.3916ZM58.2857 8.66155C56.9659 8.66155 55.8917 7.57727 55.8917 6.24497C55.8917 4.91267 56.9659 3.82839 58.2857 3.82839C59.6056 3.82839 60.6798 4.91267 60.6798 6.24497C60.6798 7.57727 59.6056 8.66155 58.2857 8.66155Z"
          fill="#F21D26"
        />
      </g>
      <g clipPath="url(#clip4_928874_135298)">
        <path
          d="M71.6314 3.3916C70.0724 3.3916 68.8047 4.67123 68.8047 6.24497C68.8047 7.81871 70.0724 9.09834 71.6314 9.09834C73.1905 9.09834 74.4582 7.81871 74.4582 6.24497C74.4582 4.67123 73.1905 3.3916 71.6314 3.3916ZM71.6314 8.66155C70.3116 8.66155 69.2374 7.57727 69.2374 6.24497C69.2374 4.91267 70.3116 3.82839 71.6314 3.82839C72.9513 3.82839 74.0255 4.91267 74.0255 6.24497C74.0255 7.57727 72.9513 8.66155 71.6314 8.66155Z"
          fill="#F21D26"
        />
      </g>
      <g clipPath="url(#clip5_928874_135298)">
        <path
          d="M84.9752 3.3916C83.4161 3.3916 82.1484 4.67123 82.1484 6.24497C82.1484 7.81871 83.4161 9.09834 84.9752 9.09834C86.5343 9.09834 87.8019 7.81871 87.8019 6.24497C87.8019 4.67123 86.5343 3.3916 84.9752 3.3916ZM84.9752 8.66155C83.6553 8.66155 82.5811 7.57727 82.5811 6.24497C82.5811 4.91267 83.6553 3.82839 84.9752 3.82839C86.2951 3.82839 87.3692 4.91267 87.3692 6.24497C87.3692 7.57727 86.2951 8.66155 84.9752 8.66155Z"
          fill="#F21D26"
        />
      </g>
      <g clipPath="url(#clip6_928874_135298)">
        <path
          d="M98.3209 3.39062C96.7618 3.39062 95.4941 4.67025 95.4941 6.24399C95.4941 7.81773 96.7618 9.09736 98.3209 9.09736C99.88 9.09736 101.148 7.81773 101.148 6.24399C101.148 4.67025 99.88 3.39062 98.3209 3.39062ZM98.3209 8.66058C97.001 8.66058 95.9269 7.5763 95.9269 6.24399C95.9269 4.91169 97.001 3.82741 98.3209 3.82741C99.6408 3.82741 100.715 4.91169 100.715 6.24399C100.715 7.5763 99.6408 8.66058 98.3209 8.66058Z"
          fill="#F21D26"
        />
      </g>
      <g clipPath="url(#clip7_928874_135298)">
        <path
          d="M111.663 3.39062C110.104 3.39062 108.836 4.67025 108.836 6.24399C108.836 7.81773 110.104 9.09736 111.663 9.09736C113.222 9.09736 114.489 7.81773 114.489 6.24399C114.489 4.67025 113.222 3.39062 111.663 3.39062ZM111.663 8.66058C110.343 8.66058 109.269 7.5763 109.269 6.24399C109.269 4.91169 110.343 3.82741 111.663 3.82741C112.983 3.82741 114.057 4.91169 114.057 6.24399C114.057 7.5763 112.983 8.66058 111.663 8.66058Z"
          fill="#F21D26"
        />
      </g>
      <g clipPath="url(#clip8_928874_135298)">
        <path
          d="M125.008 3.39062C123.449 3.39062 122.182 4.67025 122.182 6.24399C122.182 7.81773 123.449 9.09736 125.008 9.09736C126.567 9.09736 127.835 7.81773 127.835 6.24399C127.835 4.67025 126.567 3.39062 125.008 3.39062ZM125.008 8.66058C123.689 8.66058 122.614 7.5763 122.614 6.24399C122.614 4.91169 123.689 3.82741 125.008 3.82741C126.328 3.82741 127.402 4.91169 127.402 6.24399C127.402 7.5763 126.328 8.66058 125.008 8.66058Z"
          fill="#F21D26"
        />
      </g>
      <g clipPath="url(#clip9_928874_135298)">
        <path
          d="M84.9752 10.0967C83.4161 10.0967 82.1484 11.3763 82.1484 12.95C82.1484 14.5238 83.4161 15.8034 84.9752 15.8034C86.5343 15.8034 87.8019 14.5238 87.8019 12.95C87.8019 11.3763 86.5343 10.0967 84.9752 10.0967ZM84.9752 15.3666C83.6553 15.3666 82.5811 14.2824 82.5811 12.95C82.5811 11.6177 83.6553 10.5335 84.9752 10.5335C86.2951 10.5335 87.3692 11.6177 87.3692 12.95C87.3692 14.2824 86.2951 15.3666 84.9752 15.3666Z"
          fill="#F21D26"
        />
      </g>
      <g clipPath="url(#clip10_928874_135298)">
        <path
          d="M98.3189 10.0967C96.7599 10.0967 95.4922 11.3763 95.4922 12.95C95.4922 14.5238 96.7599 15.8034 98.3189 15.8034C99.878 15.8034 101.146 14.5238 101.146 12.95C101.146 11.3763 99.878 10.0967 98.3189 10.0967ZM98.3189 15.3666C96.9991 15.3666 95.9249 14.2824 95.9249 12.95C95.9249 11.6177 96.9991 10.5335 98.3189 10.5335C99.6388 10.5335 100.713 11.6177 100.713 12.95C100.713 14.2824 99.6388 15.3666 98.3189 15.3666Z"
          fill="#F21D26"
        />
      </g>
      <g clipPath="url(#clip11_928874_135298)">
        <path
          d="M111.663 10.0967C110.104 10.0967 108.836 11.3763 108.836 12.95C108.836 14.5238 110.104 15.8034 111.663 15.8034C113.222 15.8034 114.489 14.5238 114.489 12.95C114.489 11.3763 113.222 10.0967 111.663 10.0967ZM111.663 15.3666C110.343 15.3666 109.269 14.2824 109.269 12.95C109.269 11.6177 110.343 10.5335 111.663 10.5335C112.983 10.5335 114.057 11.6177 114.057 12.95C114.057 14.2824 112.983 15.3666 111.663 15.3666Z"
          fill="#F21D26"
        />
      </g>
      <g clipPath="url(#clip12_928874_135298)">
        <path
          d="M111.663 17.3057C110.104 17.3057 108.836 18.5853 108.836 20.159C108.836 21.7328 110.104 23.0124 111.663 23.0124C113.222 23.0124 114.489 21.7328 114.489 20.159C114.489 18.5853 113.222 17.3057 111.663 17.3057ZM111.663 22.5756C110.343 22.5756 109.269 21.4913 109.269 20.159C109.269 18.8267 110.343 17.7424 111.663 17.7424C112.983 17.7424 114.057 18.8267 114.057 20.159C114.057 21.4913 112.983 22.5756 111.663 22.5756Z"
          fill="#F21D26"
        />
      </g>
      <g clipPath="url(#clip13_928874_135298)">
        <path
          d="M125.008 10.0967C123.449 10.0967 122.182 11.3763 122.182 12.95C122.182 14.5238 123.449 15.8034 125.008 15.8034C126.567 15.8034 127.835 14.5238 127.835 12.95C127.835 11.3763 126.567 10.0967 125.008 10.0967ZM125.008 15.3666C123.689 15.3666 122.614 14.2824 122.614 12.95C122.614 11.6177 123.689 10.5335 125.008 10.5335C126.328 10.5335 127.402 11.6177 127.402 12.95C127.402 14.2824 126.328 15.3666 125.008 15.3666Z"
          fill="#F21D26"
        />
      </g>
      <g clipPath="url(#clip14_928874_135298)">
        <path
          d="M125.01 17.3057C123.451 17.3057 122.184 18.5853 122.184 20.159C122.184 21.7328 123.451 23.0124 125.01 23.0124C126.569 23.0124 127.837 21.7328 127.837 20.159C127.837 18.5853 126.569 17.3057 125.01 17.3057ZM125.01 22.5756C123.69 22.5756 122.616 21.4913 122.616 20.159C122.616 18.8267 123.69 17.7424 125.01 17.7424C126.33 17.7424 127.404 18.8267 127.404 20.159C127.404 21.4913 126.33 22.5756 125.01 22.5756Z"
          fill="#F21D26"
        />
      </g>
      <g clipPath="url(#clip15_928874_135298)">
        <path
          d="M11.5846 3.3916C10.0255 3.3916 8.75781 4.67123 8.75781 6.24497C8.75781 7.81871 10.0255 9.09834 11.5846 9.09834C13.1436 9.09834 14.4113 7.81871 14.4113 6.24497C14.4113 4.67123 13.1436 3.3916 11.5846 3.3916ZM11.5846 8.66155C10.2647 8.66155 9.19052 7.57727 9.19052 6.24497C9.19052 4.91267 10.2647 3.82839 11.5846 3.82839C12.9044 3.82839 13.9786 4.91267 13.9786 6.24497C13.9786 7.57727 12.9044 8.66155 11.5846 8.66155Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip16_928874_135298)">
        <path
          d="M31.6002 10.2598C30.0411 10.2598 28.7734 11.5394 28.7734 13.1131C28.7734 14.6869 30.0411 15.9665 31.6002 15.9665C33.1593 15.9665 34.4269 14.6869 34.4269 13.1131C34.4269 11.5394 33.1593 10.2598 31.6002 10.2598ZM31.6002 15.5297C30.2803 15.5297 29.2061 14.4454 29.2061 13.1131C29.2061 11.7808 30.2803 10.6966 31.6002 10.6966C32.9201 10.6966 33.9942 11.7808 33.9942 13.1131C33.9942 14.4454 32.9201 15.5297 31.6002 15.5297Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip17_928874_135298)">
        <path
          d="M31.6002 3.3623C30.0411 3.3623 28.7734 4.64193 28.7734 6.21567C28.7734 7.78941 30.0411 9.06904 31.6002 9.06904C33.1593 9.06904 34.4269 7.78941 34.4269 6.21567C34.4269 4.64193 33.1593 3.3623 31.6002 3.3623ZM31.6002 8.63226C30.2803 8.63226 29.2061 7.54798 29.2061 6.21567C29.2061 4.88337 30.2803 3.79909 31.6002 3.79909C32.9201 3.79909 33.9942 4.88337 33.9942 6.21567C33.9942 7.54798 32.9201 8.63226 31.6002 8.63226Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip18_928874_135298)">
        <path
          d="M51.6158 3.3623C50.0568 3.3623 48.7891 4.64193 48.7891 6.21567C48.7891 7.78941 50.0568 9.06904 51.6158 9.06904C53.1749 9.06904 54.4426 7.78941 54.4426 6.21567C54.4426 4.64193 53.1749 3.3623 51.6158 3.3623ZM51.6158 8.63226C50.2959 8.63226 49.2218 7.54798 49.2218 6.21567C49.2218 4.88337 50.2959 3.79909 51.6158 3.79909C52.9357 3.79909 54.0099 4.88337 54.0099 6.21567C54.0099 7.54798 52.9357 8.63226 51.6158 8.63226Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip19_928874_135298)">
        <path
          d="M64.9596 3.3623C63.4005 3.3623 62.1328 4.64193 62.1328 6.21567C62.1328 7.78941 63.4005 9.06904 64.9596 9.06904C66.5186 9.06904 67.7863 7.78941 67.7863 6.21567C67.7863 4.64193 66.5186 3.3623 64.9596 3.3623ZM64.9596 8.63226C63.6397 8.63226 62.5655 7.54798 62.5655 6.21567C62.5655 4.88337 63.6397 3.79909 64.9596 3.79909C66.2794 3.79909 67.3536 4.88337 67.3536 6.21567C67.3536 7.54798 66.2794 8.63226 64.9596 8.63226Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip20_928874_135298)">
        <path
          d="M78.3053 3.3623C76.7462 3.3623 75.4785 4.64193 75.4785 6.21567C75.4785 7.78941 76.7462 9.06904 78.3053 9.06904C79.8643 9.06904 81.132 7.78941 81.132 6.21567C81.132 4.64193 79.8643 3.3623 78.3053 3.3623ZM78.3053 8.63226C76.9854 8.63226 75.9112 7.54798 75.9112 6.21567C75.9112 4.88337 76.9854 3.79909 78.3053 3.79909C79.6251 3.79909 80.6993 4.88337 80.6993 6.21567C80.6993 7.54798 79.6251 8.63226 78.3053 8.63226Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip21_928874_135298)">
        <path
          d="M91.649 3.3623C90.09 3.3623 88.8223 4.64193 88.8223 6.21567C88.8223 7.78941 90.09 9.06904 91.649 9.06904C93.2081 9.06904 94.4758 7.78941 94.4758 6.21567C94.4758 4.64193 93.2081 3.3623 91.649 3.3623ZM91.649 8.63226C90.3291 8.63226 89.255 7.54798 89.255 6.21567C89.255 4.88337 90.3291 3.79909 91.649 3.79909C92.9689 3.79909 94.0431 4.88337 94.0431 6.21567C94.0431 7.54798 92.9689 8.63226 91.649 8.63226Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip22_928874_135298)">
        <path
          d="M104.991 3.3623C103.432 3.3623 102.164 4.64193 102.164 6.21567C102.164 7.78941 103.432 9.06904 104.991 9.06904C106.55 9.06904 107.818 7.78941 107.818 6.21567C107.818 4.64193 106.55 3.3623 104.991 3.3623ZM104.991 8.63226C103.671 8.63226 102.597 7.54798 102.597 6.21567C102.597 4.88337 103.671 3.79909 104.991 3.79909C106.311 3.79909 107.385 4.88337 107.385 6.21567C107.385 7.54798 106.311 8.63226 104.991 8.63226Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip23_928874_135298)">
        <path
          d="M118.333 3.3623C116.774 3.3623 115.506 4.64193 115.506 6.21567C115.506 7.78941 116.774 9.06904 118.333 9.06904C119.892 9.06904 121.159 7.78941 121.159 6.21567C121.159 4.64193 119.892 3.3623 118.333 3.3623ZM118.333 8.63226C117.013 8.63226 115.939 7.54798 115.939 6.21567C115.939 4.88337 117.013 3.79909 118.333 3.79909C119.652 3.79909 120.727 4.88337 120.727 6.21567C120.727 7.54798 119.652 8.63226 118.333 8.63226Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip24_928874_135298)">
        <path
          d="M131.678 3.3623C130.119 3.3623 128.852 4.64193 128.852 6.21567C128.852 7.78941 130.119 9.06904 131.678 9.06904C133.237 9.06904 134.505 7.78941 134.505 6.21567C134.505 4.64193 133.237 3.3623 131.678 3.3623ZM131.678 8.63226C130.358 8.63226 129.284 7.54798 129.284 6.21567C129.284 4.88337 130.358 3.79909 131.678 3.79909C132.998 3.79909 134.072 4.88337 134.072 6.21567C134.072 7.54798 132.998 8.63226 131.678 8.63226Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip25_928874_135298)">
        <path
          d="M78.3053 10.0684C76.7462 10.0684 75.4785 11.348 75.4785 12.9217C75.4785 14.4955 76.7462 15.7751 78.3053 15.7751C79.8643 15.7751 81.132 14.4955 81.132 12.9217C81.132 11.348 79.8643 10.0684 78.3053 10.0684ZM78.3053 15.3383C76.9854 15.3383 75.9112 14.254 75.9112 12.9217C75.9112 11.5894 76.9854 10.5051 78.3053 10.5051C79.6251 10.5051 80.6993 11.5894 80.6993 12.9217C80.6993 14.254 79.6251 15.3383 78.3053 15.3383Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip26_928874_135298)">
        <path
          d="M91.649 10.0684C90.09 10.0684 88.8223 11.348 88.8223 12.9217C88.8223 14.4955 90.09 15.7751 91.649 15.7751C93.2081 15.7751 94.4758 14.4955 94.4758 12.9217C94.4758 11.348 93.2081 10.0684 91.649 10.0684ZM91.649 15.3383C90.3291 15.3383 89.255 14.254 89.255 12.9217C89.255 11.5894 90.3291 10.5051 91.649 10.5051C92.9689 10.5051 94.0431 11.5894 94.0431 12.9217C94.0431 14.254 92.9689 15.3383 91.649 15.3383Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip27_928874_135298)">
        <path
          d="M104.991 10.0684C103.432 10.0684 102.164 11.348 102.164 12.9217C102.164 14.4955 103.432 15.7751 104.991 15.7751C106.55 15.7751 107.818 14.4955 107.818 12.9217C107.818 11.348 106.55 10.0684 104.991 10.0684ZM104.991 15.3383C103.671 15.3383 102.597 14.254 102.597 12.9217C102.597 11.5894 103.671 10.5051 104.991 10.5051C106.311 10.5051 107.385 11.5894 107.385 12.9217C107.385 14.254 106.311 15.3383 104.991 15.3383Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip28_928874_135298)">
        <path
          d="M118.333 10.0684C116.774 10.0684 115.506 11.348 115.506 12.9217C115.506 14.4955 116.774 15.7751 118.333 15.7751C119.892 15.7751 121.159 14.4955 121.159 12.9217C121.159 11.348 119.892 10.0684 118.333 10.0684ZM118.333 15.3383C117.013 15.3383 115.939 14.254 115.939 12.9217C115.939 11.5894 117.013 10.5051 118.333 10.5051C119.652 10.5051 120.727 11.5894 120.727 12.9217C120.727 14.254 119.652 15.3383 118.333 15.3383Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip29_928874_135298)">
        <path
          d="M31.6002 17.3789C30.0411 17.3789 28.7734 18.6585 28.7734 20.2323C28.7734 21.806 30.0411 23.0856 31.6002 23.0856C33.1593 23.0856 34.4269 21.806 34.4269 20.2323C34.4269 18.6585 33.1593 17.3789 31.6002 17.3789ZM31.6002 22.6489C30.2803 22.6489 29.2061 21.5646 29.2061 20.2323C29.2061 18.9 30.2803 17.8157 31.6002 17.8157C32.9201 17.8157 33.9942 18.9 33.9942 20.2323C33.9942 21.5646 32.9201 22.6489 31.6002 22.6489Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip30_928874_135298)">
        <path
          d="M31.4717 24.5C29.9835 24.5 28.7734 25.7215 28.7734 27.2237C28.7734 28.7259 29.9835 29.9473 31.4717 29.9473C32.9599 29.9473 34.17 28.7259 34.17 27.2237C34.17 25.7215 32.9599 24.5 31.4717 24.5ZM31.4717 29.5304C30.2118 29.5304 29.1865 28.4954 29.1865 27.2237C29.1865 25.9519 30.2118 24.9169 31.4717 24.9169C32.7316 24.9169 33.7569 25.9519 33.7569 27.2237C33.7569 28.4954 32.7316 29.5304 31.4717 29.5304Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip31_928874_135298)">
        <path
          d="M31.4717 31.1445C29.9835 31.1445 28.7734 32.366 28.7734 33.8682C28.7734 35.3704 29.9835 36.5919 31.4717 36.5919C32.9599 36.5919 34.17 35.3704 34.17 33.8682C34.17 32.366 32.9599 31.1445 31.4717 31.1445ZM31.4717 36.1749C30.2118 36.1749 29.1865 35.1399 29.1865 33.8682C29.1865 32.5965 30.2118 31.5615 31.4717 31.5615C32.7316 31.5615 33.7569 32.5965 33.7569 33.8682C33.7569 35.1399 32.7316 36.1749 31.4717 36.1749Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip32_928874_135298)">
        <path
          d="M31.4717 37.7891C29.9835 37.7891 28.7734 39.0105 28.7734 40.5127C28.7734 42.0149 29.9835 43.2364 31.4717 43.2364C32.9599 43.2364 34.17 42.0149 34.17 40.5127C34.17 39.0105 32.9599 37.7891 31.4717 37.7891ZM31.4717 42.8195C30.2118 42.8195 29.1865 41.7845 29.1865 40.5127C29.1865 39.241 30.2118 38.206 31.4717 38.206C32.7316 38.206 33.7569 39.241 33.7569 40.5127C33.7569 41.7845 32.7316 42.8195 31.4717 42.8195Z"
          fill="#2858E2"
        />
      </g>
      <g clipPath="url(#clip33_928874_135298)">
        <path
          d="M18.2564 3.3916C16.6974 3.3916 15.4297 4.67123 15.4297 6.24497C15.4297 7.81871 16.6974 9.09834 18.2564 9.09834C19.8155 9.09834 21.0832 7.81871 21.0832 6.24497C21.0832 4.67123 19.8155 3.3916 18.2564 3.3916ZM18.2564 8.66155C16.9366 8.66155 15.8624 7.57727 15.8624 6.24497C15.8624 4.91267 16.9366 3.82839 18.2564 3.82839C19.5763 3.82839 20.6505 4.91267 20.6505 6.24497C20.6505 7.57727 19.5763 8.66155 18.2564 8.66155Z"
          fill="#F21D26"
        />
      </g>
      <path
        d="M18.2462 10.0967C16.6928 10.0967 15.4297 11.3757 15.4297 12.9488C15.4297 14.5219 16.6928 15.8009 18.2462 15.8009C19.7997 15.8009 21.0628 14.5219 21.0628 12.9488C21.0628 11.3757 19.7997 10.0967 18.2462 10.0967ZM18.2462 15.3643C16.9311 15.3643 15.8608 14.2805 15.8608 12.9488C15.8608 11.6171 16.9311 10.5333 18.2462 10.5333C19.5613 10.5333 20.6316 11.6171 20.6316 12.9488C20.6316 14.2805 19.5613 15.3643 18.2462 15.3643Z"
        fill="#F21D26"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2799 15.1829C19.3146 14.7529 19.6894 14.4304 20.1141 14.4655C20.5387 14.5006 20.8572 14.878 20.8225 15.3102C20.7879 15.7424 20.413 16.0627 19.9884 16.0298C19.5637 15.9947 19.2453 15.6151 19.2799 15.1851V15.1829Z"
        fill="#2E4B9D"
      />
      <path
        d="M20.0507 16.1238C20.0268 16.1238 20.0052 16.1238 19.9813 16.1216C19.5047 16.0821 19.1494 15.6587 19.1862 15.176C19.2252 14.6933 19.6434 14.3335 20.12 14.3708C20.3497 14.3906 20.5598 14.4981 20.7093 14.678C20.8588 14.8557 20.9325 15.0839 20.913 15.3164C20.8761 15.7749 20.497 16.1238 20.0485 16.1238H20.0507ZM20.0507 14.5551C19.6997 14.5551 19.4007 14.8294 19.3725 15.1892C19.3422 15.5687 19.6217 15.9022 19.9965 15.9329C20.3735 15.9636 20.7007 15.6784 20.731 15.2989C20.7461 15.1146 20.6877 14.9369 20.5707 14.7964C20.4515 14.656 20.2868 14.5705 20.107 14.5551C20.0875 14.5551 20.0702 14.5529 20.0507 14.5529V14.5551Z"
        fill="white"
      />
      <path
        d="M38.2512 3.3916C36.7037 3.3916 35.4453 4.66584 35.4453 6.23295C35.4453 7.80006 36.7037 9.0743 38.2512 9.0743C39.7988 9.0743 41.0571 7.80006 41.0571 6.23295C41.0571 4.66584 39.7988 3.3916 38.2512 3.3916ZM38.2512 8.63935C36.9411 8.63935 35.8748 7.55964 35.8748 6.23295C35.8748 4.90626 36.9411 3.82655 38.2512 3.82655C39.5614 3.82655 40.6276 4.90626 40.6276 6.23295C40.6276 7.55964 39.5614 8.63935 38.2512 8.63935Z"
        fill="#F21D26"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.4459 8.16165C39.4805 7.73326 39.8539 7.41197 40.2769 7.44694C40.7 7.48191 41.0172 7.85784 40.9827 8.28842C40.9482 8.71899 40.5748 9.0381 40.1517 9.00531C39.7287 8.97034 39.4114 8.59222 39.4459 8.16383V8.16165Z"
        fill="#2E4B9D"
      />
      <path
        d="M40.2154 9.09859C40.1916 9.09859 40.17 9.09859 40.1463 9.09641C39.6715 9.05706 39.3175 8.63523 39.3542 8.15439C39.393 7.67355 39.8096 7.3151 40.2844 7.35226C40.5132 7.37193 40.7226 7.47902 40.8715 7.65825C41.0205 7.83528 41.0938 8.06259 41.0744 8.29427C41.0377 8.75107 40.66 9.09859 40.2132 9.09859H40.2154ZM40.2154 7.53585C39.8657 7.53585 39.5679 7.80906 39.5398 8.1675C39.5096 8.54562 39.788 8.87784 40.1614 8.90844C40.537 8.93904 40.8629 8.6549 40.8931 8.27679C40.9082 8.09319 40.8499 7.91615 40.7334 7.77627C40.6147 7.63639 40.4506 7.55115 40.2715 7.53585C40.2521 7.53585 40.2348 7.53366 40.2154 7.53366V7.53585Z"
        fill="white"
      />
      <path
        d="M138.329 3.3916C136.782 3.3916 135.523 4.66584 135.523 6.23295C135.523 7.80006 136.782 9.0743 138.329 9.0743C139.877 9.0743 141.135 7.80006 141.135 6.23295C141.135 4.66584 139.877 3.3916 138.329 3.3916ZM138.329 8.63935C137.019 8.63935 135.953 7.55964 135.953 6.23295C135.953 4.90626 137.019 3.82655 138.329 3.82655C139.639 3.82655 140.706 4.90626 140.706 6.23295C140.706 7.55964 139.639 8.63935 138.329 8.63935Z"
        fill="#F21D26"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M139.526 8.16263C139.561 7.73424 139.934 7.41295 140.357 7.44792C140.78 7.48289 141.097 7.85882 141.063 8.28939C141.028 8.71997 140.655 9.03907 140.232 9.00629C139.809 8.97132 139.491 8.5932 139.526 8.16481V8.16263Z"
        fill="#2E4B9D"
      />
      <path
        d="M140.295 9.09957C140.272 9.09957 140.25 9.09957 140.226 9.09738C139.752 9.05804 139.398 8.63621 139.434 8.15537C139.473 7.67452 139.89 7.31608 140.365 7.35323C140.593 7.3729 140.803 7.48 140.952 7.65922C141.101 7.83626 141.174 8.06357 141.154 8.29525C141.118 8.75205 140.74 9.09957 140.293 9.09957H140.295ZM140.295 7.53683C139.946 7.53683 139.648 7.81003 139.62 8.16848C139.59 8.5466 139.868 8.87882 140.241 8.90942C140.617 8.94002 140.943 8.65588 140.973 8.27776C140.988 8.09417 140.93 7.91713 140.813 7.77725C140.695 7.63737 140.531 7.55213 140.352 7.53683C140.332 7.53683 140.315 7.53464 140.295 7.53464V7.53683Z"
        fill="white"
      />
      <path
        d="M18.2576 17.332C16.7108 17.332 15.4531 18.6056 15.4531 20.172C15.4531 21.7383 16.7108 23.0119 18.2576 23.0119C19.8044 23.0119 21.0621 21.7383 21.0621 20.172C21.0621 18.6056 19.8023 17.332 18.2576 17.332ZM18.2576 22.5771C16.9481 22.5771 15.8824 21.498 15.8824 20.172C15.8824 18.8459 16.9481 17.7668 18.2576 17.7668C19.5671 17.7668 20.6328 18.8459 20.6328 20.172C20.6328 21.498 19.5671 22.5771 18.2576 22.5771Z"
        fill="#F21D26"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.526 18.1173C15.5605 17.6892 15.9316 17.368 16.3566 17.403C16.7794 17.4379 17.0965 17.8137 17.062 18.244C17.0275 18.6744 16.6543 18.9933 16.2314 18.9606C15.8086 18.9256 15.4915 18.5477 15.526 18.1195V18.1173Z"
        fill="#C7171E"
      />
      <path
        d="M16.2931 19.0541C16.2693 19.0541 16.2478 19.0541 16.224 19.0519C15.7494 19.0126 15.3956 18.5909 15.4323 18.1103C15.4711 17.6297 15.8875 17.2693 16.3621 17.3086C16.5908 17.3283 16.8 17.4353 16.9489 17.6144C17.0977 17.7914 17.1711 18.0186 17.1517 18.2501C17.115 18.7067 16.7375 19.0541 16.2909 19.0541H16.2931ZM16.2931 17.4921C15.9436 17.4921 15.6459 17.7652 15.6157 18.1256C15.5855 18.5035 15.8638 18.8356 16.237 18.8662C16.6102 18.8968 16.9381 18.6128 16.9683 18.2348C16.9834 18.0513 16.9252 17.8744 16.8087 17.7346C16.69 17.5948 16.5261 17.5096 16.347 17.4943C16.3276 17.4943 16.3103 17.4921 16.2909 17.4921H16.2931Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4537 22.0988C19.4883 21.6706 19.8615 21.3495 20.2843 21.3844C20.7071 21.4194 21.0243 21.7951 20.9897 22.2255C20.9552 22.6558 20.582 22.9748 20.1592 22.942C19.7363 22.9071 19.4192 22.5291 19.4537 22.101V22.0988Z"
        fill="#2E4B9D"
      />
      <path
        d="M20.2228 23.0333C20.199 23.0333 20.1774 23.0333 20.1537 23.0311C19.6791 22.9918 19.3253 22.5701 19.362 22.0895C19.4008 21.6089 19.8172 21.2507 20.2918 21.2878C20.5205 21.3075 20.7297 21.4145 20.8786 21.5936C21.0274 21.7706 21.1008 21.9978 21.0814 22.2293C21.0447 22.6859 20.6672 23.0333 20.2206 23.0333H20.2228ZM20.2228 21.4713C19.8733 21.4713 19.5756 21.7444 19.5475 22.1026C19.5173 22.4806 19.7956 22.8126 20.1688 22.8432C20.5442 22.8738 20.8699 22.5898 20.9001 22.2119C20.9152 22.0284 20.857 21.8514 20.7405 21.7116C20.6219 21.5718 20.4579 21.4866 20.2788 21.4713C20.2594 21.4713 20.2422 21.4691 20.2228 21.4691V21.4713Z"
        fill="white"
      />
      <path
        d="M4.91269 3.3916C3.35363 3.3916 2.08594 4.67123 2.08594 6.24497C2.08594 7.81871 3.35363 9.09834 4.91269 9.09834C6.47175 9.09834 7.73944 7.81871 7.73944 6.24497C7.73944 4.67123 6.47175 3.3916 4.91269 3.3916ZM4.91269 8.66155C3.59281 8.66155 2.51865 7.57727 2.51865 6.24497C2.51865 4.91267 3.59281 3.82839 4.91269 3.82839C6.23256 3.82839 7.30673 4.91267 7.30673 6.24497C7.30673 7.57727 6.23256 8.66155 4.91269 8.66155Z"
        fill="#F21D26"
      />
      <g clipPath="url(#clip34_928874_135298)">
        <path
          d="M24.9398 3.41602C23.3873 3.41602 22.125 4.69025 22.125 6.25736C22.125 7.82447 23.3873 9.09871 24.9398 9.09871C26.4923 9.09871 27.7547 7.82447 27.7547 6.25736C27.7547 4.69025 26.4902 3.41602 24.9398 3.41602ZM24.9398 8.66377C23.6255 8.66377 22.5559 7.58405 22.5559 6.25736C22.5559 4.93067 23.6255 3.85096 24.9398 3.85096C26.2542 3.85096 27.3238 4.93067 27.3238 6.25736C27.3238 7.58405 26.2542 8.66377 24.9398 8.66377Z"
          fill="#2858E2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.194 4.20266C22.2286 3.77428 22.6011 3.45299 23.0276 3.48796C23.452 3.52293 23.7703 3.89886 23.7357 4.32943C23.701 4.76001 23.3264 5.07911 22.902 5.04633C22.4776 5.01136 22.1593 4.63324 22.194 4.20485V4.20266Z"
          fill="#C7171E"
        />
        <path
          d="M22.9662 5.1399C22.9424 5.1399 22.9207 5.1399 22.8969 5.13771C22.4205 5.09837 22.0654 4.67654 22.1022 4.1957C22.1412 3.71485 22.5591 3.35422 23.0355 3.39356C23.265 3.41323 23.475 3.52033 23.6244 3.69955C23.7738 3.87659 23.8474 4.1039 23.8279 4.33558C23.7911 4.79238 23.4122 5.1399 22.964 5.1399H22.9662ZM22.9662 3.57716C22.6154 3.57716 22.3166 3.85036 22.2863 4.211C22.256 4.58911 22.5353 4.92133 22.9099 4.95193C23.2845 4.98253 23.6136 4.6984 23.6439 4.32028C23.6591 4.13668 23.6006 3.95965 23.4837 3.81976C23.3646 3.67988 23.2 3.59464 23.0203 3.57934C23.0008 3.57934 22.9835 3.57716 22.964 3.57716H22.9662Z"
          fill="white"
        />
      </g>
      <path
        d="M24.8231 10.1211C23.335 10.1211 22.125 11.3464 22.125 12.8533C22.125 14.3601 23.335 15.5854 24.8231 15.5854C26.3112 15.5854 27.5212 14.3601 27.5212 12.8533C27.5212 11.3464 26.3091 10.1211 24.8231 10.1211ZM24.8231 15.1672C23.5633 15.1672 22.538 14.129 22.538 12.8533C22.538 11.5775 23.5633 10.5393 24.8231 10.5393C26.0829 10.5393 27.1082 11.5775 27.1082 12.8533C27.1082 14.129 26.0829 15.1672 24.8231 15.1672Z"
        fill="#F21D26"
      />
      <path
        d="M27.7285 12.2217L24.1836 15.8029"
        stroke="#47AF35"
        strokeWidth={0.605707}
        strokeMiterlimit={10}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1919 10.8772C22.2251 10.4653 22.5821 10.1564 22.991 10.19C23.3978 10.2236 23.7029 10.5851 23.6696 10.9991C23.6364 11.4132 23.2774 11.72 22.8706 11.6885C22.4638 11.6549 22.1587 11.2913 22.1919 10.8794V10.8772Z"
        fill="#C7171E"
      />
      <path
        d="M22.9322 11.7787C22.9093 11.7787 22.8886 11.7787 22.8658 11.7766C22.4092 11.7388 22.0688 11.3332 22.1041 10.8708C22.1414 10.4084 22.542 10.0617 22.9986 10.0995C23.2186 10.1184 23.4199 10.2214 23.5631 10.3937C23.7063 10.564 23.7769 10.7825 23.7582 11.0053C23.7229 11.4446 23.3597 11.7787 22.9301 11.7787H22.9322ZM22.9322 10.276C22.596 10.276 22.3095 10.5388 22.2805 10.8855C22.2514 11.2491 22.5192 11.5686 22.8782 11.598C23.2373 11.6274 23.5527 11.3542 23.5818 10.9906C23.5963 10.8141 23.5403 10.6438 23.4282 10.5093C23.3141 10.3748 23.1563 10.2929 22.9841 10.2781C22.9654 10.2781 22.9488 10.276 22.9301 10.276H22.9322Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.9712 14.7064C26.0044 14.2944 26.3635 13.9855 26.7703 14.0191C27.1771 14.0527 27.4822 14.4142 27.4489 14.8282C27.4157 15.2423 27.0567 15.5491 26.6499 15.5176C26.2431 15.484 25.938 15.1204 25.9712 14.7085V14.7064Z"
        fill="#2E4B9D"
      />
      <path
        d="M26.7115 15.6056C26.6886 15.6056 26.6679 15.6056 26.6451 15.6035C26.1885 15.5657 25.8481 15.16 25.8834 14.6977C25.9207 14.2353 26.3213 13.8906 26.7779 13.9264C26.9979 13.9453 27.1992 14.0483 27.3424 14.2206C27.4856 14.3908 27.5562 14.6094 27.5375 14.8322C27.5022 15.2714 27.139 15.6056 26.7094 15.6056H26.7115ZM26.7115 14.1029C26.3753 14.1029 26.0888 14.3656 26.0619 14.7103C26.0328 15.0739 26.3005 15.3933 26.6596 15.4228C27.0207 15.4522 27.3341 15.179 27.3632 14.8154C27.3777 14.6388 27.3217 14.4686 27.2096 14.3341C27.0954 14.1996 26.9377 14.1176 26.7654 14.1029C26.7468 14.1029 26.7302 14.1008 26.7115 14.1008V14.1029Z"
        fill="white"
      />
      <path
        d="M25.0189 13.8066H24.8073V12.1151C24.7665 12.1559 24.6999 12.2002 24.6076 12.2482C24.5154 12.2952 24.4311 12.328 24.3548 12.3466V12.1204C24.4524 12.0929 24.5571 12.0481 24.6689 11.986C24.7807 11.9239 24.8689 11.8635 24.9337 11.805H25.0189V13.8066Z"
        fill="#47AF35"
      />
      <path
        d="M38.1668 24.5391C36.6787 24.5391 35.4688 25.7643 35.4688 27.2712C35.4688 28.7781 36.6787 30.0034 38.1668 30.0034C39.6549 30.0034 40.8649 28.7781 40.8649 27.2712C40.8649 25.7643 39.6529 24.5391 38.1668 24.5391ZM38.1668 29.5852C36.907 29.5852 35.8818 28.5469 35.8818 27.2712C35.8818 25.9955 36.907 24.9573 38.1668 24.9573C39.4266 24.9573 40.4519 25.9955 40.4519 27.2712C40.4519 28.5469 39.4266 29.5852 38.1668 29.5852Z"
        fill="#F21D26"
      />
      <path
        d="M41.0722 26.6416L37.5273 30.2228"
        stroke="#47AF35"
        strokeWidth={0.605707}
        strokeMiterlimit={10}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5376 25.2962C35.5708 24.8843 35.9278 24.5753 36.3367 24.6089C36.7435 24.6426 37.0486 25.0041 37.0153 25.4181C36.9821 25.8321 36.6231 26.139 36.2163 26.1074C35.8095 26.0738 35.5044 25.7102 35.5376 25.2983V25.2962Z"
        fill="#C7171E"
      />
      <path
        d="M36.2759 26.1977C36.2531 26.1977 36.2323 26.1977 36.2095 26.1956C35.7529 26.1577 35.4125 25.7521 35.4478 25.2898C35.4852 24.8274 35.8857 24.4806 36.3423 24.5184C36.5623 24.5374 36.7637 24.6403 36.9069 24.8127C37.0501 24.9829 37.1206 25.2015 37.102 25.4243C37.0667 25.8635 36.7035 26.1977 36.2739 26.1977H36.2759ZM36.2759 24.695C35.9397 24.695 35.6533 24.9577 35.6242 25.3045C35.5952 25.6681 35.8629 25.9875 36.222 26.0169C36.581 26.0464 36.8965 25.7731 36.9255 25.4096C36.9401 25.233 36.884 25.0628 36.772 24.9283C36.6578 24.7938 36.5001 24.7118 36.3278 24.6971C36.3091 24.6971 36.2925 24.695 36.2739 24.695H36.2759Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.315 29.1253C39.3482 28.7134 39.7072 28.4044 40.114 28.4381C40.5208 28.4717 40.8259 28.8332 40.7927 29.2472C40.7595 29.6612 40.4004 29.9681 39.9936 29.9365C39.5869 29.9029 39.2818 29.5393 39.315 29.1274V29.1253Z"
        fill="#2E4B9D"
      />
      <path
        d="M40.0552 30.0255C40.0324 30.0255 40.0116 30.0255 39.9888 30.0234C39.5322 29.9856 39.1918 29.58 39.2271 29.1176C39.2645 28.6552 39.665 28.3106 40.1216 28.3463C40.3416 28.3652 40.543 28.4682 40.6862 28.6405C40.8294 28.8108 40.8999 29.0293 40.8813 29.2521C40.846 29.6914 40.4828 30.0255 40.0532 30.0255H40.0552ZM40.0552 28.5228C39.719 28.5228 39.4326 28.7855 39.4056 29.1302C39.3766 29.4938 39.6443 29.8133 40.0033 29.8427C40.3645 29.8721 40.6779 29.5989 40.7069 29.2353C40.7214 29.0588 40.6654 28.8885 40.5533 28.754C40.4392 28.6195 40.2814 28.5375 40.1092 28.5228C40.0905 28.5228 40.0739 28.5207 40.0552 28.5207V28.5228Z"
        fill="white"
      />
      <path
        d="M38.4851 26.7485C38.4851 26.6314 38.4505 26.5396 38.3813 26.473C38.3121 26.4065 38.2202 26.3732 38.1058 26.3732C38.0241 26.3732 37.9425 26.3945 37.8609 26.4371C37.7793 26.4788 37.7056 26.5365 37.64 26.6101V26.3812C37.7668 26.2561 37.931 26.1935 38.1324 26.1935C38.3001 26.1935 38.435 26.2419 38.537 26.3386C38.6399 26.4353 38.6914 26.5658 38.6914 26.7299C38.6914 26.871 38.6585 26.9947 38.5929 27.1012C38.5272 27.2068 38.4154 27.3159 38.2575 27.4286C38.1084 27.5333 38.0046 27.6127 37.9461 27.6669C37.8884 27.721 37.8476 27.7724 37.8236 27.8212C37.8006 27.8691 37.789 27.9264 37.789 27.9929H38.7393V28.1846H37.5641V28.0887C37.5641 27.9814 37.5787 27.89 37.608 27.8146C37.6382 27.7383 37.6879 27.6642 37.7571 27.5923C37.8272 27.5196 37.939 27.43 38.0925 27.3235C38.2327 27.2268 38.3329 27.1349 38.3932 27.048C38.4545 26.9601 38.4851 26.8603 38.4851 26.7485Z"
        fill="#47AF35"
      />
      <path
        d="M111.559 24.54C110.071 24.54 108.861 25.7653 108.861 27.2722C108.861 28.7791 110.071 30.0044 111.559 30.0044C113.048 30.0044 114.257 28.7791 114.257 27.2722C114.257 25.7653 113.045 24.54 111.559 24.54ZM111.559 29.5861C110.3 29.5861 109.274 28.5479 109.274 27.2722C109.274 25.9965 110.3 24.9583 111.559 24.9583C112.819 24.9583 113.844 25.9965 113.844 27.2722C113.844 28.5479 112.819 29.5861 111.559 29.5861Z"
        fill="#F21D26"
      />
      <path
        d="M114.463 26.6406L110.918 30.2219"
        stroke="#47AF35"
        strokeWidth={0.605707}
        strokeMiterlimit={10}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.926 25.2962C108.96 24.8843 109.316 24.5753 109.725 24.6089C110.132 24.6426 110.437 25.0041 110.404 25.4181C110.371 25.8321 110.012 26.139 109.605 26.1074C109.198 26.0738 108.893 25.7102 108.926 25.2983V25.2962Z"
        fill="#C7171E"
      />
      <path
        d="M109.667 26.1977C109.644 26.1977 109.623 26.1977 109.6 26.1956C109.144 26.1577 108.803 25.7521 108.838 25.2898C108.876 24.8274 109.276 24.4806 109.733 24.5184C109.953 24.5374 110.154 24.6403 110.297 24.8127C110.441 24.9829 110.511 25.2015 110.493 25.4243C110.457 25.8635 110.094 26.1977 109.664 26.1977H109.667ZM109.667 24.695C109.33 24.695 109.044 24.9577 109.015 25.3045C108.986 25.6681 109.254 25.9875 109.613 26.0169C109.972 26.0464 110.287 25.7731 110.316 25.4096C110.331 25.233 110.275 25.0628 110.163 24.9283C110.048 24.7938 109.891 24.7118 109.718 24.6971C109.7 24.6971 109.683 24.695 109.664 24.695H109.667Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.706 29.1253C112.739 28.7134 113.098 28.4044 113.505 28.4381C113.911 28.4717 114.217 28.8332 114.183 29.2472C114.15 29.6612 113.791 29.9681 113.384 29.9365C112.977 29.9029 112.672 29.5393 112.706 29.1274V29.1253Z"
        fill="#2E4B9D"
      />
      <path
        d="M113.446 30.0255C113.423 30.0255 113.402 30.0255 113.379 30.0234C112.923 29.9856 112.582 29.58 112.618 29.1176C112.655 28.6552 113.056 28.3106 113.512 28.3463C113.732 28.3652 113.934 28.4682 114.077 28.6405C114.22 28.8108 114.291 29.0293 114.272 29.2521C114.237 29.6914 113.873 30.0255 113.444 30.0255H113.446ZM113.446 28.5228C113.11 28.5228 112.823 28.7855 112.796 29.1302C112.767 29.4938 113.035 29.8133 113.394 29.8427C113.755 29.8721 114.068 29.5989 114.098 29.2353C114.112 29.0588 114.056 28.8885 113.944 28.754C113.83 28.6195 113.672 28.5375 113.5 28.5228C113.481 28.5228 113.465 28.5207 113.446 28.5207V28.5228Z"
        fill="white"
      />
      <path
        d="M111.876 26.7485C111.876 26.6314 111.841 26.5396 111.772 26.473C111.703 26.4065 111.611 26.3732 111.496 26.3732C111.415 26.3732 111.333 26.3945 111.252 26.4371C111.17 26.4788 111.096 26.5365 111.031 26.6101V26.3812C111.157 26.2561 111.322 26.1935 111.523 26.1935C111.691 26.1935 111.826 26.2419 111.928 26.3386C112.031 26.4353 112.082 26.5658 112.082 26.7299C112.082 26.871 112.049 26.9947 111.984 27.1012C111.918 27.2068 111.806 27.3159 111.648 27.4286C111.499 27.5333 111.395 27.6127 111.337 27.6669C111.279 27.721 111.238 27.7724 111.214 27.8212C111.191 27.8691 111.18 27.9264 111.18 27.9929H112.13V28.1846H110.955V28.0887C110.955 27.9814 110.969 27.89 110.999 27.8146C111.029 27.7383 111.078 27.6642 111.148 27.5923C111.218 27.5196 111.33 27.43 111.483 27.3235C111.623 27.2268 111.724 27.1349 111.784 27.048C111.845 26.9601 111.876 26.8603 111.876 26.7485Z"
        fill="#47AF35"
      />
      <defs>
        <clipPath id="clip0_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(35.3086 10.3525)"
          />
        </clipPath>
        <clipPath id="clip1_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(35.3086 17.5703)"
          />
        </clipPath>
        <clipPath id="clip2_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(42.1172 3.39062)"
          />
        </clipPath>
        <clipPath id="clip3_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(55.4609 3.3916)"
          />
        </clipPath>
        <clipPath id="clip4_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(68.8047 3.3916)"
          />
        </clipPath>
        <clipPath id="clip5_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(82.1484 3.3916)"
          />
        </clipPath>
        <clipPath id="clip6_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(95.4922 3.39062)"
          />
        </clipPath>
        <clipPath id="clip7_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(108.836 3.39062)"
          />
        </clipPath>
        <clipPath id="clip8_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(122.182 3.39062)"
          />
        </clipPath>
        <clipPath id="clip9_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(82.1484 10.0967)"
          />
        </clipPath>
        <clipPath id="clip10_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(95.4922 10.0967)"
          />
        </clipPath>
        <clipPath id="clip11_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(108.836 10.0967)"
          />
        </clipPath>
        <clipPath id="clip12_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(108.836 17.3057)"
          />
        </clipPath>
        <clipPath id="clip13_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(122.182 10.0967)"
          />
        </clipPath>
        <clipPath id="clip14_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(122.182 17.3057)"
          />
        </clipPath>
        <clipPath id="clip15_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(8.75781 3.3916)"
          />
        </clipPath>
        <clipPath id="clip16_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(28.7734 10.2598)"
          />
        </clipPath>
        <clipPath id="clip17_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(28.7734 3.3623)"
          />
        </clipPath>
        <clipPath id="clip18_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(48.7891 3.3623)"
          />
        </clipPath>
        <clipPath id="clip19_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(62.1328 3.3623)"
          />
        </clipPath>
        <clipPath id="clip20_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(75.4785 3.3623)"
          />
        </clipPath>
        <clipPath id="clip21_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(88.8223 3.3623)"
          />
        </clipPath>
        <clipPath id="clip22_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(102.164 3.3623)"
          />
        </clipPath>
        <clipPath id="clip23_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(115.508 3.3623)"
          />
        </clipPath>
        <clipPath id="clip24_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(128.852 3.3623)"
          />
        </clipPath>
        <clipPath id="clip25_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(75.4785 10.0684)"
          />
        </clipPath>
        <clipPath id="clip26_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(88.8223 10.0684)"
          />
        </clipPath>
        <clipPath id="clip27_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(102.164 10.0684)"
          />
        </clipPath>
        <clipPath id="clip28_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(115.508 10.0684)"
          />
        </clipPath>
        <clipPath id="clip29_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(28.7734 17.3789)"
          />
        </clipPath>
        <clipPath id="clip30_928874_135298">
          <rect
            width={5.39652}
            height={5.44734}
            fill="white"
            transform="translate(28.7734 24.5)"
          />
        </clipPath>
        <clipPath id="clip31_928874_135298">
          <rect
            width={5.39652}
            height={5.44734}
            fill="white"
            transform="translate(28.7734 31.1445)"
          />
        </clipPath>
        <clipPath id="clip32_928874_135298">
          <rect
            width={5.39652}
            height={5.44734}
            fill="white"
            transform="translate(28.7734 37.7891)"
          />
        </clipPath>
        <clipPath id="clip33_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(15.4297 3.3916)"
          />
        </clipPath>
        <clipPath id="clip34_928874_135298">
          <rect
            width={5.6535}
            height={5.70674}
            fill="white"
            transform="translate(22.1016 3.3916)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const DarkThemeFlag = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 157 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_928910_99542)">
        <g clipPath="url(#clip1_928910_99542)">
          <path
            d="M34.2528 1.22266C31.9428 1.22266 30.0645 3.10097 30.0645 5.411C30.0645 7.72102 31.9428 9.59933 34.2528 9.59933C36.5628 9.59933 38.4411 7.72102 38.4411 5.411C38.4411 3.10097 36.5596 1.22266 34.2528 1.22266ZM34.2528 8.9582C32.2972 8.9582 30.7056 7.36663 30.7056 5.411C30.7056 3.45536 32.2972 1.86379 34.2528 1.86379C36.2084 1.86379 37.8 3.45536 37.8 5.411C37.8 7.36663 36.2084 8.9582 34.2528 8.9582Z"
            fill="#2E4B9D"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M30.1757 2.38233C30.2273 1.751 30.7813 1.2775 31.4158 1.32903C32.0472 1.38057 32.5207 1.9346 32.4691 2.56916C32.4176 3.20371 31.8603 3.67399 31.229 3.62568C30.5977 3.57414 30.1242 3.01689 30.1757 2.38555V2.38233Z"
            fill="#C7171E"
          />
          <path
            d="M31.3244 3.76387C31.289 3.76387 31.2568 3.76387 31.2214 3.76065C30.5126 3.70266 29.9843 3.08091 30.0391 2.37217C30.097 1.66344 30.7188 1.13189 31.4275 1.18987C31.769 1.21887 32.0815 1.37672 32.3038 1.64089C32.5261 1.90183 32.6356 2.23687 32.6066 2.57835C32.5518 3.25165 31.9881 3.76387 31.3212 3.76387H31.3244ZM31.3244 1.46048C30.8026 1.46048 30.358 1.86317 30.3129 2.39472C30.2678 2.95205 30.6834 3.44172 31.2407 3.48682C31.798 3.53192 32.2877 3.11312 32.3328 2.5558C32.3553 2.28519 32.2683 2.02425 32.0944 1.81807C31.9172 1.61189 31.6724 1.48625 31.405 1.4637C31.376 1.4637 31.3502 1.46048 31.3212 1.46048H31.3244Z"
            fill="white"
          />
        </g>
        <g clipPath="url(#clip2_928910_99542)">
          <path
            d="M4.37793 1.18555C2.05813 1.18555 0.171875 3.0718 0.171875 5.39161C0.171875 7.71141 2.05813 9.59766 4.37793 9.59766C6.69774 9.59766 8.58399 7.71141 8.58399 5.39161C8.58399 3.0718 6.69774 1.18555 4.37793 1.18555ZM4.37793 8.95381C2.41403 8.95381 0.815725 7.35551 0.815725 5.39161C0.815725 3.4277 2.41403 1.8294 4.37793 1.8294C6.34184 1.8294 7.94014 3.4277 7.94014 5.39161C7.94014 7.35551 6.34184 8.95381 4.37793 8.95381Z"
            fill="#C7171E"
          />
        </g>
        <g clipPath="url(#clip3_928910_99542)">
          <path
            d="M24.2314 1.18652C21.9116 1.18652 20.0254 3.07278 20.0254 5.39258C20.0254 7.71238 21.9116 9.59864 24.2314 9.59864C26.5513 9.59864 28.4375 7.71238 28.4375 5.39258C28.4375 3.07278 26.5513 1.18652 24.2314 1.18652ZM24.2314 8.95479C22.2675 8.95479 20.6692 7.35649 20.6692 5.39258C20.6692 3.42868 22.2675 1.83037 24.2314 1.83037C26.1954 1.83037 27.7937 3.42868 27.7937 5.39258C27.7937 7.35649 26.1954 8.95479 24.2314 8.95479Z"
            fill="#C7171E"
          />
        </g>
        <g clipPath="url(#clip4_928910_99542)">
          <path
            d="M24.2314 29.2822C21.9116 29.2822 20.0254 31.1351 20.0254 33.4139C20.0254 35.6927 21.9116 37.5456 24.2314 37.5456C26.5513 37.5456 28.4375 35.6927 28.4375 33.4139C28.4375 31.1351 26.5513 29.2822 24.2314 29.2822ZM24.2314 36.9132C22.2675 36.9132 20.6692 35.3431 20.6692 33.4139C20.6692 31.4847 22.2675 29.9147 24.2314 29.9147C26.1954 29.9147 27.7937 31.4847 27.7937 33.4139C27.7937 35.3431 26.1954 36.9132 24.2314 36.9132Z"
            fill="#C7171E"
          />
        </g>
        <g clipPath="url(#clip5_928910_99542)">
          <path
            d="M24.2314 38.6562C21.9116 38.6562 20.0254 40.4961 20.0254 42.7588C20.0254 45.0215 21.9116 46.8613 24.2314 46.8613C26.5513 46.8613 28.4375 45.0215 28.4375 42.7588C28.4375 40.4961 26.5513 38.6562 24.2314 38.6562ZM24.2314 46.2333C22.2675 46.2333 20.6692 44.6744 20.6692 42.7588C20.6692 40.8432 22.2675 39.2843 24.2314 39.2843C26.1954 39.2843 27.7937 40.8432 27.7937 42.7588C27.7937 44.6744 26.1954 46.2333 24.2314 46.2333Z"
            fill="#C7171E"
          />
        </g>
        <circle cx={13.9371} cy={13.5114} r={1.31995} fill="#4B4B4B" />
        <circle cx={33.1696} cy={14.2653} r={1.31995} fill="#4B4B4B" />
        <circle cx={33.1696} cy={24.0709} r={1.31995} fill="#4B4B4B" />
        <circle cx={33.1696} cy={34.2535} r={1.31995} fill="#4B4B4B" />
        <circle cx={33.1696} cy={44.4362} r={1.31995} fill="#4B4B4B" />
        <circle cx={43.3551} cy={14.2653} r={1.31995} fill="#4B4B4B" />
        <circle cx={43.3551} cy={24.0709} r={1.31995} fill="#4B4B4B" />
        <circle cx={43.3551} cy={34.2535} r={1.31995} fill="#4B4B4B" />
        <circle cx={62.9606} cy={33.8756} r={1.31995} fill="#4B4B4B" />
        <circle cx={72.7653} cy={13.8883} r={1.31995} fill="#4B4B4B" />
        <circle cx={82.1969} cy={13.8883} r={1.31995} fill="#4B4B4B" />
        <circle cx={121.793} cy={13.8883} r={1.31995} fill="#4B4B4B" />
        <circle cx={101.808} cy={13.8883} r={1.31995} fill="#4B4B4B" />
        <circle cx={141.406} cy={13.8883} r={1.31995} fill="#4B4B4B" />
        <circle cx={92.0035} cy={13.8883} r={1.31995} fill="#4B4B4B" />
        <circle cx={131.599} cy={13.8883} r={1.31995} fill="#4B4B4B" />
        <circle cx={111.613} cy={13.8883} r={1.31995} fill="#4B4B4B" />
        <circle cx={151.211} cy={13.8883} r={1.31995} fill="#4B4B4B" />
        <circle cx={82.1969} cy={4.46057} r={1.31995} fill="#4B4B4B" />
        <circle cx={121.793} cy={4.46057} r={1.31995} fill="#4B4B4B" />
        <circle cx={101.808} cy={4.46057} r={1.31995} fill="#4B4B4B" />
        <circle cx={141.406} cy={4.46057} r={1.31995} fill="#4B4B4B" />
        <circle cx={92.0035} cy={4.46057} r={1.31995} fill="#4B4B4B" />
        <circle cx={131.599} cy={4.46057} r={1.31995} fill="#4B4B4B" />
        <circle cx={111.613} cy={4.46057} r={1.31995} fill="#4B4B4B" />
        <circle cx={151.211} cy={4.46057} r={1.31995} fill="#4B4B4B" />
        <circle cx={72.7653} cy={23.694} r={1.31995} fill="#4B4B4B" />
        <circle cx={82.1969} cy={23.694} r={1.31995} fill="#4B4B4B" />
        <circle cx={121.793} cy={23.694} r={1.31995} fill="#4B4B4B" />
        <circle cx={101.808} cy={23.694} r={1.31995} fill="#4B4B4B" />
        <circle cx={141.406} cy={23.694} r={1.31995} fill="#4B4B4B" />
        <circle cx={92.0035} cy={23.694} r={1.31995} fill="#4B4B4B" />
        <circle cx={131.599} cy={23.694} r={1.31995} fill="#4B4B4B" />
        <circle cx={111.613} cy={23.694} r={1.31995} fill="#4B4B4B" />
        <circle cx={151.211} cy={23.694} r={1.31995} fill="#4B4B4B" />
        <circle cx={72.7653} cy={33.4996} r={1.31995} fill="#4B4B4B" />
        <circle cx={82.1969} cy={33.4996} r={1.31995} fill="#4B4B4B" />
        <circle cx={121.793} cy={33.4996} r={1.31995} fill="#4B4B4B" />
        <circle cx={101.808} cy={33.4996} r={1.31995} fill="#4B4B4B" />
        <circle cx={141.406} cy={33.4996} r={1.31995} fill="#4B4B4B" />
        <circle cx={92.0035} cy={33.4996} r={1.31995} fill="#4B4B4B" />
        <circle cx={131.599} cy={33.4996} r={1.31995} fill="#4B4B4B" />
        <circle cx={111.613} cy={33.4996} r={1.31995} fill="#4B4B4B" />
        <circle cx={151.211} cy={33.4996} r={1.31995} fill="#4B4B4B" />
        <circle cx={72.7653} cy={43.3043} r={1.31995} fill="#4B4B4B" />
        <circle cx={82.1969} cy={43.3043} r={1.31995} fill="#4B4B4B" />
        <circle cx={121.793} cy={43.3043} r={1.31995} fill="#4B4B4B" />
        <circle cx={101.808} cy={43.3043} r={1.31995} fill="#4B4B4B" />
        <circle cx={141.406} cy={43.3043} r={1.31995} fill="#4B4B4B" />
        <circle cx={92.0035} cy={43.3043} r={1.31995} fill="#4B4B4B" />
        <circle cx={131.599} cy={43.3043} r={1.31995} fill="#4B4B4B" />
        <circle cx={111.613} cy={43.3043} r={1.31995} fill="#4B4B4B" />
        <circle cx={151.211} cy={43.3043} r={1.31995} fill="#4B4B4B" />
        <circle cx={72.7653} cy={53.11} r={1.31995} fill="#4B4B4B" />
        <circle cx={82.1969} cy={53.109} r={1.31995} fill="#4B4B4B" />
        <circle cx={121.793} cy={53.109} r={1.31995} fill="#4B4B4B" />
        <circle cx={101.808} cy={53.109} r={1.31995} fill="#4B4B4B" />
        <circle cx={141.406} cy={53.109} r={1.31995} fill="#4B4B4B" />
        <circle cx={92.0035} cy={53.109} r={1.31995} fill="#4B4B4B" />
        <circle cx={131.599} cy={53.109} r={1.31995} fill="#4B4B4B" />
        <circle cx={111.613} cy={53.11} r={1.31995} fill="#4B4B4B" />
        <circle cx={151.211} cy={53.109} r={1.31995} fill="#4B4B4B" />
        <circle cx={62.9606} cy={42.9274} r={1.31995} fill="#4B4B4B" />
        <circle cx={62.9606} cy={53.11} r={1.31995} fill="#4B4B4B" />
        <circle cx={43.3551} cy={44.4352} r={1.31995} fill="#4B4B4B" />
        <circle cx={13.9371} cy={33.8766} r={1.31995} fill="#4B4B4B" />
        <circle cx={3.7555} cy={33.8766} r={1.31995} fill="#4B4B4B" />
        <circle cx={13.9371} cy={23.317} r={1.31995} fill="#4B4B4B" />
        <circle cx={13.9371} cy={43.6813} r={1.31995} fill="#4B4B4B" />
        <circle cx={13.9371} cy={53.109} r={1.31995} fill="#4B4B4B" />
        <circle cx={33.1696} cy={53.11} r={1.31995} fill="#4B4B4B" />
        <circle cx={43.3551} cy={53.109} r={1.31995} fill="#4B4B4B" />
        <circle cx={3.7555} cy={43.6813} r={1.31995} fill="#4B4B4B" />
        <circle cx={3.7555} cy={53.11} r={1.31995} fill="#4B4B4B" />
        <circle cx={22.9899} cy={53.109} r={1.31995} fill="#4B4B4B" />
        <circle cx={3.7555} cy={23.317} r={1.31995} fill="#4B4B4B" />
        <circle cx={4.13245} cy={13.5114} r={1.31995} fill="#4B4B4B" />
        <path
          d="M14.3057 1.15137C11.9859 1.15137 10.0996 3.0435 10.0996 5.37053C10.0996 7.69756 11.9859 9.5897 14.3057 9.5897C16.6255 9.5897 18.5117 7.69756 18.5117 5.37053C18.5117 3.0435 16.6255 1.15137 14.3057 1.15137ZM14.3057 8.94384C12.3418 8.94384 10.7435 7.34056 10.7435 5.37053C10.7435 3.40051 12.3418 1.79722 14.3057 1.79722C16.2696 1.79722 17.8679 3.40051 17.8679 5.37053C17.8679 7.34056 16.2696 8.94384 14.3057 8.94384Z"
          fill="#2E4B9D"
        />
        <path
          d="M24.2117 10.4961C21.9017 10.4961 20.0234 12.3802 20.0234 14.6975C20.0234 17.0147 21.9017 18.8988 24.2117 18.8988C26.5218 18.8988 28.4001 17.0147 28.4001 14.6975C28.4001 12.3802 26.5218 10.4961 24.2117 10.4961ZM24.2117 18.2557C22.2561 18.2557 20.6646 16.6592 20.6646 14.6975C20.6646 12.7357 22.2561 11.1392 24.2117 11.1392C26.1674 11.1392 27.7589 12.7357 27.7589 14.6975C27.7589 16.6592 26.1674 18.2557 24.2117 18.2557Z"
          fill="#C7171E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.998 17.5477C26.0495 16.9144 26.6068 16.4395 27.2381 16.4912C27.8694 16.5429 28.3429 17.0986 28.2914 17.7351C28.2398 18.3717 27.6826 18.8434 27.0513 18.7949C26.4199 18.7432 25.9464 18.1843 25.998 17.551V17.5477Z"
          fill="#2E4B9D"
        />
        <path
          d="M27.1467 18.9345C27.1113 18.9345 27.079 18.9345 27.0436 18.9313C26.3349 18.8731 25.8066 18.2495 25.8613 17.5385C25.9193 16.8276 26.5411 16.2976 27.2498 16.3525C27.5913 16.3816 27.9038 16.54 28.126 16.805C28.3483 17.0667 28.4578 17.4028 28.4289 17.7453C28.3741 18.4207 27.8103 18.9345 27.1435 18.9345H27.1467ZM27.1467 16.624C26.6248 16.624 26.1803 17.0279 26.1384 17.5579C26.0933 18.117 26.5088 18.6082 27.0662 18.6534C27.6267 18.6986 28.1131 18.2785 28.1582 17.7195C28.1808 17.448 28.0938 17.1863 27.9199 16.9795C27.7427 16.7726 27.4978 16.6466 27.2305 16.624C27.2015 16.624 27.1757 16.6208 27.1467 16.6208V16.624Z"
          fill="white"
        />
        <path
          d="M24.3163 19.8789C22.0154 19.8789 20.1445 21.7556 20.1445 24.0637C20.1445 26.3718 22.0154 28.2485 24.3163 28.2485C26.6173 28.2485 28.4882 26.3718 28.4882 24.0637C28.4882 21.7556 26.614 19.8789 24.3163 19.8789ZM24.3163 27.6079C22.3684 27.6079 20.7831 26.0177 20.7831 24.0637C20.7831 22.1097 22.3684 20.5195 24.3163 20.5195C26.2643 20.5195 27.8495 22.1097 27.8495 24.0637C27.8495 26.0177 26.2643 27.6079 24.3163 27.6079Z"
          fill="#C7171E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M20.2499 21.0367C20.3013 20.4059 20.8531 19.9328 21.4851 19.9843C22.114 20.0358 22.5856 20.5894 22.5343 21.2234C22.483 21.8574 21.9279 22.3273 21.2991 22.279C20.6702 22.2275 20.1986 21.6707 20.2499 21.0399V21.0367Z"
          fill="#C7171E"
        />
        <path
          d="M21.3936 22.4179C21.3583 22.4179 21.3262 22.4179 21.2909 22.4147C20.585 22.3568 20.0587 21.7356 20.1133 21.0274C20.171 20.3193 20.7903 19.7882 21.4963 19.8461C21.8364 19.8751 22.1476 20.0328 22.3691 20.2968C22.5905 20.5575 22.6996 20.8922 22.6707 21.2334C22.6161 21.9062 22.0546 22.4179 21.3904 22.4179H21.3936ZM21.3936 20.1165C20.8737 20.1165 20.4309 20.5189 20.386 21.05C20.3411 21.6068 20.755 22.0961 21.3101 22.1411C21.8653 22.1862 22.353 21.7677 22.3979 21.2109C22.4204 20.9405 22.3338 20.6798 22.1605 20.4738C21.984 20.2678 21.7401 20.1423 21.4738 20.1197C21.4449 20.1197 21.4192 20.1165 21.3904 20.1165H21.3936Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M26.0898 26.9029C26.1411 26.2721 26.6961 25.799 27.325 25.8505C27.9538 25.902 28.4255 26.4556 28.3741 27.0896C28.3228 27.7236 27.7677 28.1935 27.1389 28.1452C26.5101 28.0937 26.0384 27.5369 26.0898 26.9061V26.9029Z"
          fill="#2E4B9D"
        />
        <path
          d="M27.2334 28.2808C27.1981 28.2808 27.166 28.2808 27.1307 28.2776C26.4248 28.2196 25.8986 27.5984 25.9531 26.8903C26.0109 26.1821 26.6302 25.6543 27.3361 25.709C27.6762 25.7379 27.9875 25.8957 28.2089 26.1596C28.4303 26.4203 28.5394 26.7551 28.5105 27.0963C28.456 27.769 27.8944 28.2808 27.2302 28.2808H27.2334ZM27.2334 25.9794C26.7136 25.9794 26.2708 26.3817 26.2291 26.9096C26.1841 27.4664 26.5981 27.9557 27.1532 28.0008C27.7115 28.0458 28.1961 27.6274 28.241 27.0705C28.2634 26.8002 28.1768 26.5394 28.0035 26.3334C27.827 26.1274 27.5832 26.0019 27.3168 25.9794C27.288 25.9794 27.2623 25.9761 27.2334 25.9761V25.9794Z"
          fill="white"
        />
        <path
          d="M54.0869 1.15137C51.7671 1.15137 49.8809 3.0435 49.8809 5.37053C49.8809 7.69756 51.7671 9.5897 54.0869 9.5897C56.4067 9.5897 58.293 7.69756 58.293 5.37053C58.293 3.0435 56.4067 1.15137 54.0869 1.15137ZM54.0869 8.94384C52.123 8.94384 50.5247 7.34056 50.5247 5.37053C50.5247 3.40051 52.123 1.79722 54.0869 1.79722C56.0508 1.79722 57.6491 3.40051 57.6491 5.37053C57.6491 7.34056 56.0508 8.94384 54.0869 8.94384Z"
          fill="#2E4B9D"
        />
        <path
          d="M73.9404 1.15137C71.6206 1.15137 69.7344 3.0435 69.7344 5.37053C69.7344 7.69756 71.6206 9.5897 73.9404 9.5897C76.2602 9.5897 78.1465 7.69756 78.1465 5.37053C78.1465 3.0435 76.2602 1.15137 73.9404 1.15137ZM73.9404 8.94384C71.9765 8.94384 70.3782 7.34056 70.3782 5.37053C70.3782 3.40051 71.9765 1.79722 73.9404 1.79722C75.9043 1.79722 77.5026 3.40051 77.5026 5.37053C77.5026 7.34056 75.9043 8.94384 73.9404 8.94384Z"
          fill="#2E4B9D"
        />
        <path
          d="M54.0869 10.4961C51.7671 10.4961 49.8809 12.3882 49.8809 14.7153C49.8809 17.0423 51.7671 18.9344 54.0869 18.9344C56.4067 18.9344 58.293 17.0423 58.293 14.7153C58.293 12.3882 56.4067 10.4961 54.0869 10.4961ZM54.0869 18.2886C52.123 18.2886 50.5247 16.6853 50.5247 14.7153C50.5247 12.7452 52.123 11.142 54.0869 11.142C56.0508 11.142 57.6491 12.7452 57.6491 14.7153C57.6491 16.6853 56.0508 18.2886 54.0869 18.2886Z"
          fill="#2E4B9D"
        />
        <path
          d="M54.0869 19.8428C51.7671 19.8428 49.8809 21.7349 49.8809 24.0619C49.8809 26.389 51.7671 28.2811 54.0869 28.2811C56.4067 28.2811 58.293 26.389 58.293 24.0619C58.293 21.7349 56.4067 19.8428 54.0869 19.8428ZM54.0869 27.6352C52.123 27.6352 50.5247 26.032 50.5247 24.0619C50.5247 22.0919 52.123 20.4886 54.0869 20.4886C56.0508 20.4886 57.6491 22.0919 57.6491 24.0619C57.6491 26.032 56.0508 27.6352 54.0869 27.6352Z"
          fill="#2E4B9D"
        />
        <path
          d="M54.0869 29.1875C51.7671 29.1875 49.8809 31.0796 49.8809 33.4067C49.8809 35.7337 51.7671 37.6258 54.0869 37.6258C56.4067 37.6258 58.293 35.7337 58.293 33.4067C58.293 31.0796 56.4067 29.1875 54.0869 29.1875ZM54.0869 36.98C52.123 36.98 50.5247 35.3767 50.5247 33.4067C50.5247 31.4366 52.123 29.8334 54.0869 29.8334C56.0508 29.8334 57.6491 31.4366 57.6491 33.4067C57.6491 35.3767 56.0508 36.98 54.0869 36.98Z"
          fill="#2E4B9D"
        />
        <path
          d="M54.0869 38.5322C51.7671 38.5322 49.8809 40.4244 49.8809 42.7514C49.8809 45.0784 51.7671 46.9706 54.0869 46.9706C56.4067 46.9706 58.293 45.0784 58.293 42.7514C58.293 40.4244 56.4067 38.5322 54.0869 38.5322ZM54.0869 46.3247C52.123 46.3247 50.5247 44.7214 50.5247 42.7514C50.5247 40.7814 52.123 39.1781 54.0869 39.1781C56.0508 39.1781 57.6491 40.7814 57.6491 42.7514C57.6491 44.7214 56.0508 46.3247 54.0869 46.3247Z"
          fill="#2E4B9D"
        />
        <path
          d="M54.0869 47.8779C51.7671 47.8779 49.8809 49.7701 49.8809 52.0971C49.8809 54.4241 51.7671 56.3163 54.0869 56.3163C56.4067 56.3163 58.293 54.4241 58.293 52.0971C58.293 49.7701 56.4067 47.8779 54.0869 47.8779ZM54.0869 55.6704C52.123 55.6704 50.5247 54.0671 50.5247 52.0971C50.5247 50.1271 52.123 48.5238 54.0869 48.5238C56.0508 48.5238 57.6491 50.1271 57.6491 52.0971C57.6491 54.0671 56.0508 55.6704 54.0869 55.6704Z"
          fill="#2E4B9D"
        />
        <path
          d="M44.0277 1.1875C41.803 1.1875 39.9941 3.00202 39.9941 5.2336C39.9941 7.46518 41.803 9.2797 44.0277 9.2797C46.2523 9.2797 48.0612 7.46518 48.0612 5.2336C48.0612 3.00202 46.2492 1.1875 44.0277 1.1875ZM44.0277 8.66034C42.1443 8.66034 40.6116 7.12282 40.6116 5.2336C40.6116 3.34438 42.1443 1.80686 44.0277 1.80686C45.911 1.80686 47.4438 3.34438 47.4438 5.2336C47.4438 7.12282 45.911 8.66034 44.0277 8.66034Z"
          fill="#C7171E"
        />
        <path
          d="M48.3735 4.29883L43.0742 9.60214"
          stroke="#47AF35"
          strokeWidth={1.17301}
          strokeMiterlimit={10}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M40.0935 2.30737C40.1432 1.69747 40.6767 1.24005 41.2878 1.28984C41.8958 1.33963 42.3518 1.87484 42.3022 2.48785C42.2526 3.10085 41.7159 3.55516 41.1079 3.50849C40.4999 3.4587 40.0439 2.92037 40.0935 2.31048V2.30737Z"
          fill="#C7171E"
        />
        <path
          d="M41.1987 3.6431C41.1645 3.6431 41.1335 3.6431 41.0994 3.63999C40.4168 3.58397 39.908 2.98334 39.9608 2.29867C40.0166 1.61401 40.6154 1.10051 41.2979 1.15652C41.6268 1.18453 41.9277 1.33703 42.1418 1.59222C42.3559 1.8443 42.4614 2.16796 42.4334 2.49785C42.3807 3.14828 41.8378 3.6431 41.1956 3.6431H41.1987ZM41.1987 1.41794C40.6961 1.41794 40.2679 1.80696 40.2245 2.32046C40.1811 2.85885 40.5813 3.33189 41.118 3.37546C41.6547 3.41903 42.1263 3.01446 42.1697 2.47606C42.1914 2.21464 42.1077 1.96256 41.9401 1.76339C41.7695 1.56421 41.5337 1.44284 41.2762 1.42105C41.2483 1.42105 41.2235 1.41794 41.1956 1.41794H41.1987Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M45.7381 7.97827C45.7877 7.36837 46.3244 6.91095 46.9324 6.96074C47.5404 7.01053 47.9964 7.54574 47.9467 8.15875C47.8971 8.77175 47.3604 9.22606 46.7524 9.17939C46.1444 9.1296 45.6884 8.59127 45.7381 7.98138V7.97827Z"
          fill="#2E4B9D"
        />
        <path
          d="M46.8471 9.31066C46.813 9.31066 46.7819 9.31066 46.7478 9.30755C46.0653 9.25153 45.5565 8.65089 45.6092 7.96623C45.6651 7.28156 46.2638 6.77117 46.9464 6.82408C47.2752 6.85209 47.5762 7.00458 47.7902 7.25978C48.0043 7.51186 48.1098 7.83552 48.0819 8.1654C48.0291 8.81583 47.4862 9.31066 46.844 9.31066H46.8471ZM46.8471 7.0855C46.3445 7.0855 45.9164 7.47451 45.876 7.9849C45.8326 8.5233 46.2328 8.99634 46.7695 9.03991C47.3094 9.08348 47.7778 8.6789 47.8213 8.14051C47.843 7.87909 47.7592 7.62701 47.5917 7.42783C47.4211 7.22866 47.1853 7.10728 46.9278 7.0855C46.8998 7.0855 46.875 7.08239 46.8471 7.08239V7.0855Z"
          fill="white"
        />
        <path
          d="M44.3915 7.67969H43.9817V4.40381C43.9027 4.48285 43.7738 4.56876 43.5951 4.66155C43.4164 4.75261 43.2532 4.81619 43.1054 4.85227V4.41412C43.2944 4.36085 43.4972 4.27408 43.7137 4.1538C43.9302 4.03352 44.1011 3.91668 44.2266 3.80327H44.3915V7.67969Z"
          fill="#47AF35"
        />
        <path
          d="M64.0147 1.18652C61.6948 1.18652 59.8086 3.07278 59.8086 5.39258C59.8086 7.71238 61.6948 9.59864 64.0147 9.59864C66.3345 9.59864 68.2207 7.71238 68.2207 5.39258C68.2207 3.07278 66.3345 1.18652 64.0147 1.18652ZM64.0147 8.95479C62.0507 8.95479 60.4524 7.35649 60.4524 5.39258C60.4524 3.42868 62.0507 1.83037 64.0147 1.83037C65.9786 1.83037 67.5769 3.42868 67.5769 5.39258C67.5769 7.35649 65.9786 8.95479 64.0147 8.95479Z"
          fill="#C7171E"
        />
        <path
          d="M64.0147 10.501C61.6948 10.501 59.8086 12.3872 59.8086 14.707C59.8086 17.0268 61.6948 18.9131 64.0147 18.9131C66.3345 18.9131 68.2207 17.0268 68.2207 14.707C68.2207 12.3872 66.3345 10.501 64.0147 10.501ZM64.0147 18.2692C62.0507 18.2692 60.4524 16.6709 60.4524 14.707C60.4524 12.7431 62.0507 11.1448 64.0147 11.1448C65.9786 11.1448 67.5769 12.7431 67.5769 14.707C67.5769 16.6709 65.9786 18.2692 64.0147 18.2692Z"
          fill="#C7171E"
        />
        <path
          d="M64.0147 19.8174C61.6948 19.8174 59.8086 21.7036 59.8086 24.0234C59.8086 26.3432 61.6948 28.2295 64.0147 28.2295C66.3345 28.2295 68.2207 26.3432 68.2207 24.0234C68.2207 21.7036 66.3345 19.8174 64.0147 19.8174ZM64.0147 27.5856C62.0507 27.5856 60.4524 25.9873 60.4524 24.0234C60.4524 22.0595 62.0507 20.4612 64.0147 20.4612C65.9786 20.4612 67.5769 22.0595 67.5769 24.0234C67.5769 25.9873 65.9786 27.5856 64.0147 27.5856Z"
          fill="#C7171E"
        />
      </g>
      <defs>
        <clipPath id="clip0_928910_99542">
          <rect
            width={156.64}
            height={55.5819}
            fill="white"
            transform="translate(0.273438 0.708984)"
          />
        </clipPath>
        <clipPath id="clip1_928910_99542">
          <rect
            width={8.41212}
            height={8.43833}
            fill="white"
            transform="translate(30.0312 1.15137)"
          />
        </clipPath>
        <clipPath id="clip2_928910_99542">
          <rect
            width={8.41212}
            height={8.43833}
            fill="white"
            transform="translate(0.171875 1.15137)"
          />
        </clipPath>
        <clipPath id="clip3_928910_99542">
          <rect
            width={8.41212}
            height={8.43833}
            fill="white"
            transform="translate(20.0254 1.15137)"
          />
        </clipPath>
        <clipPath id="clip4_928910_99542">
          <rect
            width={8.41212}
            height={8.43833}
            fill="white"
            transform="translate(20.0254 29.1875)"
          />
        </clipPath>
        <clipPath id="clip5_928910_99542">
          <rect
            width={8.41212}
            height={8.43833}
            fill="white"
            transform="translate(20.0254 38.5332)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CheckNotifIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 30 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 30.5C23.2843 30.5 30 23.7843 30 15.5C30 12.1988 28.9336 9.1466 27.1264 6.66924L14.6884 20.4893C13.9957 21.2589 12.8301 21.3726 12.0018 20.7513L6.06667 16.3C5.62484 15.9686 5.5353 15.3418 5.86667 14.9C6.19804 14.4582 6.82484 14.3686 7.26667 14.7L13.2018 19.1513L25.8293 5.1208C23.0991 2.27301 19.2566 0.5 15 0.5C6.71573 0.5 0 7.21573 0 15.5C0 23.7843 6.71573 30.5 15 30.5Z"
        fill="#69FF36"
      />
    </svg>
  );
};

export const ExitIcon = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.15385 15L0 13.8462L6.34615 7.5L0 1.15385L1.15385 0L7.5 6.34615L13.8462 0L15 1.15385L8.65385 7.5L15 13.8462L13.8462 15L7.5 8.65385L1.15385 15Z"
        fill="white"
      />
    </svg>
  );
};
